import React,{useEffect, useState} from "react";
import services from "../../services/services";
import DateObject from "react-date-object";

const TimesheetHeader = () => {
   //const useridval = 4;
   const [loggedUser, setLoggedUser] = useState('');
   const [useridval, setuseridval] = useState(0);

   useEffect(() =>
   {
    var loggedinUserRole = sessionStorage.getItem('userRole');
    setLoggedUser(loggedinUserRole);

    var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
   },[1]);

  return(
  <>
            <ul class="nav nav-tabs" role="tablist">
            {(loggedUser.toLowerCase() === 'employee' || loggedUser.toLowerCase() === 'manager') ? 
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/Timesheet' || window.location.pathname === '/timesheet') ? 'active' : ''}`} href="/timesheet">This Week's Timesheet</a>
                </li>
                :'' }
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/Pending-For-Approval' || window.location.pathname === '/pending-for-approval' || window.location.pathname === '/submitted-weekview' || window.location.pathname === '/timesheet-management') ? 'active' : ''}`} href="/pending-for-approval">Pending Approval</a>
                </li>
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/Unsubmitted-List' || window.location.pathname === '/unsubmitted-list') ? 'active' : ''}`} href="/unsubmitted-list">Unsubmitted </a>
                </li>
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/Approved-List' || window.location.pathname === '/approved-list' || window.location.pathname === '/approved-weekview') ? 'active' : ''}`} href="/approved-list">Approved  </a>
                </li>
            </ul>
  </>
)};

TimesheetHeader.propTypes = {};

TimesheetHeader.defaultProps = {};

export default TimesheetHeader;
