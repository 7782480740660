import React, { useEffect, useState } from "react";
import services from "../../services/services";
import $ from 'jquery';
import { useForm } from "react-hook-form";
import { DeleteConfirmationPopup } from "../../confirmation-dialogue/delete-dialogue";

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
      <div className="popup">
        <div className="popup-content">
          <p>{message}</p>
          <button onClick={onConfirm} class="btn btn-primary">Yes</button>
          <button onClick={onCancel} class="btn btn-outline-dark">No</button>
        </div>
      </div>
    );
  }

  function ConfirmationAlertPopup({ message, onConfirm, onCancel }) {
    return (
      <div className="popup">
        <div className="popup-content">
          <p>{message}</p>
          <button onClick={onCancel} class="btn btn-outline-dark">Cancel</button>
        </div>
      </div>
    );
  }
  
const Profile = () => {

    const {
        //register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    var loggedUserId = sessionStorage.getItem('userIdval');
    const [useridval, setuseridval] = useState(loggedUserId);
    const [loggedUser, setLoggedUser] = useState('');
    const [userProfileInfo, setUserProfileInfo] = useState([]);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [base64Image, setBase64Image] = useState("");
    const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());
    const [formErrors, setFormErrors] = useState({ email: "", password: "" });
    const [showPopup, setShowPopup] = useState(false);
    const [showAlertPopup, setShowAlertPopup] = useState(false); 
    const [alertErrorMessage, setAlertErrorMessage] = useState('');       
    const [showLoader, setShowLoader] = useState(0);

    
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {

            // Check file size (2MB = 2 * 1024 * 1024 bytes)
            const maxSize = 2 * 1024 * 1024;
            if (file.size > maxSize) {
               // alert('File size exceeds the maximum limit of 2MB.');
               setAlertErrorMessage('File size exceeds the maximum limit of 2MB.')
               setShowAlertPopup(true);
                return;
            }

            // Check file type
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            if (!allowedTypes.includes(file.type)) {
                //alert('Invalid file format. Only PNG, JPG, and GIF formats are allowed.');
                setAlertErrorMessage('Invalid file format. Only PNG, JPG, and GIF formats are allowed.')
                setShowAlertPopup(true);
                return;
            }


            setSelectedFile(file);
            const reader = new FileReader();

            reader.onloadend = async () => { // Declare this as async
                setShowLoader(1);
                const base64String = reader.result.split(',')[1];

                const requestJsonTask = {
                    base64Image: base64String,
                };

                try {
                    const result1_fetchTaskWiseStatsUser = await services.updateProfilePic(requestJsonTask, loggedUserId);

                    setBase64Image(base64String);
                    setUserProfileInfo(prevState => ({
                        ...prevState,
                        profile_image_path: reader.result // Update this with the server path after upload if needed
                    }));
                    setShowLoader(0);
                } catch (error) {
                    console.error('Error updating profile picture:', error);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleCancel = () => {
        setShowPopup(false);
        setShowAlertPopup(false);
     }
     

     const handleDeleteClick = () => {
        setShowPopup(true); // Show the confirmation popup when delete is clicked
      };

    const handleDeleteImage = async () => {
        setShowPopup(false);
        // Reset the image-related states
        setSelectedFile(null);
        setBase64Image("");
        setUserProfileInfo(prevState => ({
            ...prevState,
            profile_image_path: "images/profile-picture.jpg" // Set the profile picture path to empty
        }));

        const requestJsonTask = {
            base64Image: " ",
        };
        try {
            const result1_fetchTaskWiseStatsUser = await services.deleteProfilePic(requestJsonTask, loggedUserId);
        } catch (error) {
            console.error('Error updating profile picture:', error);
        }

    };


    // Function to get the full URL of the profile image
    const getProfileImageUrl = (path) => {
        console.log(path);
        console.log(apiEndPoint)
        const baseUrl = apiEndPoint; // Access environment variable
        const staticFolder = "";//process.env.REACT_APP_STATIC_FOLDER || ''; // Optional: If you have a static folder
        const fallbackImage = "images/profile-picture.jpg"; // Fallback image path

        // Return the full image URL if path is available, else return fallback
        return path ? `${baseUrl}${staticFolder}/${path}` : fallbackImage;
    };

    const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        const cleaned = value.replace(/\D/g, '');

        // Split into groups of 3, 3, and 4 digits
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        // if (match) {
        //   if (match[2]) {
        //     return `${match[1]} ${match[2]}${match[3] ? ` ${match[3]}` : '-'}`;
        //   }
        //   return match[1];
        // }

        if (match) {
            return [match[1], match[2], match[3]].filter(Boolean).join('-');
        }

        return value;
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
        const formattedPhoneNumber = formatPhoneNumber(numericValue);
        setPhoneNumber(formattedPhoneNumber);
    };

    useEffect(() => {
        $('.loadwrapper').hide();
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);
        $('#updatesuccessMessage').hide();
        $('#failuresuccessMessage').hide();
        getUserProfile(useridval);
    }, []);

    const getUserProfile = (loggeduserid) => {
        services.getUsersInfoById(loggeduserid).then((res) => {
            if (res.data.type === 'success') {
                setUserProfileInfo(res.data.data);
                setUserProfileInfo(prevState => ({
                    ...prevState,
                    profile_image_path: getProfileImageUrl(res.data.data.profile_image_path) // You may need to update this with the server path after upload
                }));
                $('#userfirst_name').val(res.data.data.first_name);
                $('#userlast_name').val(res.data.data.last_name);
                setPhoneNumber(res.data.data.phone_number);
                $('.loadwrapper').hide();
            }
        });
    }

    const submitValue = () => {

        let errors = { firstName: "", lastName: "" };
        let isValid = true;

        /*
        if (!email) {
          errors.email = "Email is required";
          isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.email = "Email is invalid";
          isValid = false;
        }
          */

        var fname = $('#userfirst_name').val();
        var lname = $('#userlast_name').val();

        if (fname == "") {
            errors.firstName = 1;
            isValid = false;
        }
        if (!lname) {
            errors.lastName = 1;
            isValid = false;
        }


        setFormErrors(errors);
        if (!isValid) {
            return false;
        }


        setShowLoader(1);
        const requestObject =
        {
            "company_id": userProfileInfo.company_id,
            "first_name": $('#userfirst_name').val(),
            "last_name": $('#userlast_name').val(),
            "phone_number": phoneNumber,
            "email": userProfileInfo.email,
            "role_id": userProfileInfo.role_id,
            "designation_id": userProfileInfo.designation_id,
            "employee_id": userProfileInfo.designation_id,
            "status": 1
        }
        console.log(requestObject); //return false;
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };


        services.updateUserProfile(requestObject, useridval).then((res) => {
            if (res.type === 'success') {
                sessionStorage.setItem('userFullName', $('#userfirst_name').val()+' '+$('#userlast_name').val());
                $("#updatesuccessMessage").show();
                $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                    $("#updatesuccessMessage").slideUp(500);
                });
                setTimeout(function () {
                    getUserProfile(useridval);
                }, 1000);
                setShowLoader(0);
            }
            if (res.type == 'error') {
                $("#failuresuccessMessage").html(res.message);
                setShowLoader(0);
                $("#failuresuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                    $("#failuresuccessMessage").slideUp(500);
                });
                setTimeout(function () {
                    getUserProfile(useridval);
                }, 1000);
            }

        });
    }

    return (
        <>
            <main>
                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            User Profile
                        </h2>

                        {/* <div class="userwrap">
            <span class="userpic">
                <div class="del"><img src="images/delete-red.svg" alt=""/></div>
                <img src="images/picture.jpg" alt=""/>
            </span>
            <span class="d-grid align-items-center">
                <button>
                    < img src="images/upload.svg" alt=""/>
                    <span class="text-uppercase">Upload photo</span>
            </button>
            <small>At least 256 X 256px in PNG or JPG <br/>
                Max 2 MB file </small>
            </span>
        </div> */}

                        {/* <div className="userwrap">
                          <span className="userpic">
                              <div className="del">
                                  <img src="images/delete-red.svg" alt="Delete" />
                              </div>
                              <img src="images/picture.jpg" alt="User Picture" />
                          </span>
                          <span className="d-grid align-items-center">
                              <button type="button" onClick={() => document.getElementById('fileInput').click()}>
                                  <img src="images/upload.svg" alt="Upload" />
                                  <span className="text-uppercase">Upload photo</span>
                              </button>
                              <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: "none" }}
                                  accept="image/png, image/jpeg"
                                  onChange={handleFileChange}
                              />
                              <small>At least 256 X 256px in PNG or JPG <br />
                                  Max 2 MB file</small>
                          </span>
                      </div> */}


                        <div className="userwrap">
                            <span className="userpic">
                                <div className="del" onClick={handleDeleteClick}>
                                    <img src="images/delete-red.svg" alt="Delete" />
                                </div>

                                
                                {/* <img src={userProfileInfo.profile_image_path} alt="User Picture" />  */}

                                <img
                                    src={userProfileInfo.profile_image_path ? userProfileInfo.profile_image_path : 'images/profile-picture.jpg'}
                                    alt="User Picture"
                                />

                            </span>
                            <span className="d-grid align-items-center">
                                <button type="button" onClick={() => document.getElementById('fileInput').click()}>
                                    <img src="images/upload.svg" alt="Upload" className="me-1" />
                                    <span className="text-uppercase">Upload photo</span>
                                </button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg"
                                    onChange={handleFileChange}
                                />
                                <small>At least 256 X 256px in PNG or JPG <br />
                                    Max 2 MB file</small>
                            </span>
                        </div>

                        <div class="mainbox my-4">
                            <h3 class="my-5 basicinfotxt">Basic Information</h3>

                            <div class="container px-0">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <form id="userprofile" onSubmit={handleSubmit(submitValue)}>
                                            <div class="mb-3 user-from-wrap">
                                                <label for="exampleInputEmail1" class="form-label">First Name *</label>
                                                {/* <input type="text" class="form-control" id="userfirst_name" aria-describedby="emailHelp" /> */}

                                                <div>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formErrors.firstName ? 'is-invalid' : ''}`}
                                                        id="userfirst_name"

                                                    />

                                                    {formErrors.firstName && (
                                                        <span className="invalid-feedback" style={{ display: 'block' }}>

                                                            First Name is required
                                                        </span>
                                                    )}                                                 </div>
                                            </div>
                                            <div class="mb-3 user-from-wrap">
                                                <label for="exampleInputEmail1" class="form-label">Last Name *</label>
                                                {/* <input type="text" class="form-control" id="userlast_name" aria-describedby="emailHelp" /> */}
                                                <div>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formErrors.lastName ? 'is-invalid' : ''}`}
                                                        id="userlast_name"
                                                    // Add other necessary props like value, onChange, etc.
                                                    />
                                                    {formErrors.lastName && (
                                                        <span className="invalid-feedback" style={{ display: 'block' }}>
                                                            Last Name is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="mb-3 user-from-wrap">
                                                <label for="exampleInputEmail1" class="form-label">Phone Number</label>
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={phoneNumber} onChange={handleInputChange} maxLength={12} />

                                            </div>
                                            <div class="mb-3 user-from-wrap">
                                                <label for="exampleInputEmail1" class="form-label">Email Id</label>
                                                <input disabled type="email" id="disabledTextInput" class="form-control" placeholder="myid@nexasi.com" value={userProfileInfo.email} />
                                            </div>

                                            <div class="d-flex justify-content-between mb-3 align-items-center ">

                                                <button type="submit" class="btn btn-primary" style={{ "margin-left": "200px" }}> Update Profile</button>
                                                <a href="/changepassword">Change Password</a>
                                            </div>

                                            <div id="updatesuccessMessage" class="alert alert-success" role="alert">Profile Updated successfully!</div>
                                            <div id="failuresuccessMessage" class="alert alert-danger" role="alert"></div>

                                            {showLoader === 1 ?
                                            <div class="loadwrapper">
                                                <div class="clock"><div class="circle"></div></div>
                                            </div>
                                            : '' }

                                        </form>
                                        <div class="p-3 border d-none border-radius align-items-center justify-content-between" style={{ "border-radius": "8px" }}>
                                            <p class="mb-0">It has been so long since to changed the password</p>
                                            <a href="/changepassword">Change Password</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                {showPopup && (
                        <DeleteConfirmationPopup
                        message="Are you sure you want to Delete this image?"
                        onConfirm={handleDeleteImage}
                        onCancel={handleCancel}
                        />
                    )}

{showAlertPopup && (
                        <ConfirmationAlertPopup
                        message={alertErrorMessage}
                        onCancel={handleCancel}
                        />
                    )}

            </main>
        </>
    )
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
