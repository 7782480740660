//import React, { useEffect, useState } from 'react';
import React, { history, useEffect, useState, useMemo } from "react";
import { createPopper } from '@popperjs/core';
import services from "../../services/services";
import jwtDecode from 'jwt-decode';

const PublicHeader = () => {
const [loggedUserRole, setLoggedUserRole] = useState('');
const [loggedUserId, setLoggedUserId] = useState(sessionStorage.getItem('userIdval'));
const [loggedUserName, setLoggedUserName] = useState('');
const [loggedUserAvatar, setLoggedUserAvatar] = useState('');
const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());

    useEffect(() => {
        var userRole = sessionStorage.getItem('userRole');
        setLoggedUserRole(userRole);

        var userId = sessionStorage.getItem('userIdval');
        setLoggedUserId(userId);

        var loggedusername = sessionStorage.getItem('userFullName');
        var loggeduserAvatar = sessionStorage.getItem('userProfileAvatar');
        if(!isEmpty(loggedusername)) {
            setLoggedUserName(loggedusername);
            setLoggedUserAvatar(loggeduserAvatar);
        } else {
            services.getUsersInfoById(userId).then((res) => {
                if(res.data.type === 'success'){
                    console.log(res.data.data);
                    var userFullName = res.data.data.first_name+' '+res.data.data.last_name;
                    sessionStorage.setItem('userFullName', userFullName);
                    setLoggedUserName(userFullName);

                    var userProfileImage = res.data.data.profile_image_path;
                    sessionStorage.setItem('userProfileAvatar', userProfileImage);
                    setLoggedUserAvatar(userProfileImage)
                }
            });
        }
    }, []);

    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    const handleClickLogout = () => {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.clear();
        window.location.href = "/login"; 
    };

    // Function to get the full URL of the profile image
    const getProfileImageUrl = (path) => {
        console.log(path);
        console.log(apiEndPoint)
        const baseUrl = apiEndPoint; // Access environment variable
        const staticFolder = "";//process.env.REACT_APP_STATIC_FOLDER || ''; // Optional: If you have a static folder
        const fallbackImage = "images/profile-picture.jpg"; // Fallback image path

        // Return the full image URL if path is available, else return fallback
        return path ? `${baseUrl}${staticFolder}/${path}` : fallbackImage;
    };

    //###########################
    //const loggedUserId = sessionStorage.getItem('userIdval');
    const accessToken = sessionStorage.getItem('accessToken');
  
     if (!loggedUserId || !accessToken) {
        return null;
     } 
  
     // Decode the access token
     const decodedToken = jwtDecode(accessToken);
  
     // Check if the token is expired
     const currentTime = Math.floor(Date.now() / 1000);
     if (decodedToken.exp < currentTime) {
        return null;
     }
      //########################################
    return (
        <>

           {/* <header>*/}
                <div class="main-nav-container">
                    <div class="container">
                        <nav class="navbar navbar-expand-lg ms-auto">
                            <div class="container-fluid px-0">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                {loggedUserRole === 'Employee' ?
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav topnav">
                                        <li class={`nav-item ${window.location.pathname == '/dashboard' ? 'active' : '' }`}>
                                            <a class="nav-link" aria-current="page" href="/dashboard">Dashboard</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/timesheet' ? 'active' : '' }`}>
                                            <a class="nav-link " href="/timesheet">Timesheet</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/projects' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/projects">Projects</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/reports' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/reports">Reports</a>
                                        </li>
                                        {/* <li class={`nav-item dropdown ${window.location.pathname == '/reports' ? 'active' : '' }`}>
                                            <a class="nav-link dropdown-toggle" href="/reports" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Reports
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="/reports">Weekly Reports</a></li>
                                                <li><a class="dropdown-item" href="/reports">Monthly Reports</a></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>

                                : (loggedUserRole === 'Manager') ?
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav topnav">
                                        <li class={`nav-item ${window.location.pathname == '/manager-dashboard' ? 'active' : '' }`}>
                                            <a class="nav-link " aria-current="page" href="/manager-dashboard">Dashboard</a>
                                        </li>
                                        <li class={`nav-item dropdown ${(window.location.pathname == '/timesheet' || window.location.pathname == '/timesheet-management') ? 'active' : '' }`}>
                                            <a class="nav-link dropdown-toggle" href="/timesheet" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Timesheet
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="/timesheet">My Timesheet</a></li>
                                                <li><a class="dropdown-item" href="/timesheet-management">Timesheet management</a></li>

                                            </ul>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/project-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/project-management">Project Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/user-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/user-management">User Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/detailed-reports' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/detailed-reports">Reports</a>
                                        </li>
                                    </ul>
                                </div>
                                :(loggedUserRole === 'Admin') ?
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav topnav">
                                        <li class={`nav-item ${window.location.pathname == '/admin-dashboard' ? 'active' : '' }`}>
                                            <a class="nav-link" aria-current="page" href="/admin-dashboard">Dashboard</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/timesheet-management' ? 'active' : '' }`}>
                                            <a class="nav-link" aria-current="page" href="/timesheet-management">Timesheet</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/project-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/project-management">Project Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/user-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/user-management">User Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/detailed-reports' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/detailed-reports">Reports</a>
                                        </li>
                                    </ul>
                                </div>

                                : ''
                                }

                                <div class="ms-auto">
                                    <ul class="navbar-nav topnav">
                                        <li class="nav-item position-relative">
                                            <div class="count-notofication">3</div>
                                            <a class="nav-link " aria-current="page" href="#">
                                                <img src="images/bell.png" alt="User Picture" />
                                            </a>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {/*  */}
                                                <div class="user"><img src={loggedUserAvatar ? getProfileImageUrl(loggedUserAvatar) : 'images/profile-picture.jpg'} alt="" /></div>
                                                <span>{loggedUserName}</span>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="/profile">My Profile</a></li>
                                                <li><a class="dropdown-item" onClick={handleClickLogout}>Logout</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>




                            </div>
                        </nav>
                    </div>
                </div>
           {/* </header> */}

        </>

    );
};

export default PublicHeader;