import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import services from "../../services/services";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import AddTime from "../modals/add-time";
import TaskDescription from "../modals/task-description";
import ApprovedTimesheet from "./approved-timesheet";
import TimesheetHeader from "./timesheet-header";
import $ from 'jquery';
import { useForm } from "react-hook-form";
import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from "../../timezone/dateUtils";
import { DeleteConfirmationPopup } from "../../confirmation-dialogue/delete-dialogue";
import { Tooltip } from "../../confirmation-dialogue/tooltip";


const TimesheetLanding = () => {
   const navigate = useNavigate();
   const [date, setDate] = useState(getCurrentDateInEST());

   const [showCalendar, setShowCalendar] = useState(false);
   var showReturntoToday = 0;
   var todayDate = getCurrentDateInEST();

   const [showTimeEtriesList, setShowTimeEtriesList] = useState([]);
   const [showingResultsForDate, setShowingResultsForDate] = useState(getCurrentDateInEST());
   const [weekStartDate, setWeekStartDate] = useState('');
   const [weekEndDate, setWeekEndDate] = useState('');
   const [totalEntryTime, setTotalEntryTime] = useState('');
   const [totalBillableTime, setTotalBillableTime] = useState('');
   const [totalNonBillableTime, setTotalNonBillableTime] = useState('');
   const [totalStatus, setTotalStatus] = useState('');

   const [totalEntryTimeWeek, setTotalEntryTimeWeek] = useState('');
   const [totalBillableTimeWeek, setTotalBillableTimeWeek] = useState('');
   const [totalNonBillableTimeWeek, setTotalNonBillableTimeWeek] = useState('');
   const [totalStatusWeek, setTotalStatusWeek] = useState('');

   const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
   const [totalWeekTime, setTotalWeekTime] = useState('');
   const [dateWiseTimes, setDateWiseTimes] = useState([]);
   const [weekLimitDates, setWeekLimitDates] = useState('');
   const [showReturntoWeek, setshowReturntoWeek] = useState(0);
   const [dayweektoggle, setDayWeekToggle] = useState('day');
   const [showWeekTimeEtriesList, setShowWeekTimeEtriesList] = useState([]);
   const [timesheetfreeze , setTimesheetFreeze] = useState(false);
   const [timesheetStatusHolidays, setTimesheetStatusHolidays] = useState([]);
   const [dayWekkIsChecked, setDayWekkIsChecked] = useState(false);
   const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
   const [showPopup, setShowPopup] = useState(false);
   const  [showLoader, setShowLoader] = useState(0);

   const {
      register,
      formState: { errors },
      handleSubmit
  } = useForm({
      criteriaMode: "all",mode: "onBlur"
  });

   function getParameterByName(name) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(window.location.href);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
   }

   const handleDateChange = (newDate) => {
      setDate(newDate);
      setShowingResultsForDate(newDate);
      getWeekDates(newDate);
      setShowCalendar(false); // Hide calendar after date selection
   };

   const getWeekDates = (date) => {
      var datechangedobject = new DateObject(date);
      var datechangeformattedforentries = datechangedobject.format("YYYY-MM-DD");

      const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
      const newstartOfWeek = new Date(date);
      const dayDifference = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Adjust to Monday
      newstartOfWeek.setDate(date.getDate() + dayDifference);

      const week = [];
      for (let i = 0; i < 7; i++) {
         const weekDate = new Date(newstartOfWeek);
         weekDate.setDate(newstartOfWeek.getDate() + i);
         week.push(weekDate);
      }

      let weekyear1 = week[0].getFullYear();
      let weekmonth1 = ('0' + (week[0].getMonth() + 1)).slice(-2);
      let weekday1 = ('0' + week[0].getDate()).slice(-2);

      let weekendyear1 = week[6].getFullYear();
      let weekendmonth1 = ('0' + (week[6].getMonth() + 1)).slice(-2);
      let weekendday1 = ('0' + week[6].getDate()).slice(-2);

      let weekStartDate1 = `${weekyear1}-${weekmonth1}-${weekday1}`;
      let weekEndDate1 = `${weekendyear1}-${weekendmonth1}-${weekendday1}`;

      setWeekStartDate(weekStartDate1);
      setWeekEndDate(weekEndDate1);

      if (dayweektoggle === 'day') {
         setDayWekkIsChecked(false);
         getTimesheetEntries('', '', '', '', '', '', useridval, datechangeformattedforentries, '', '');
      }
      if (dayweektoggle === 'week') {
         setDayWekkIsChecked(true);
         getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekStartDate1, weekEndDate1);
      }
      else {

      }
      getFullWeekTimesInfo(useridval, weekStartDate1, weekEndDate1);
   };

   const toggleCalendar = (event) => {
      event.preventDefault();
      setShowCalendar(!showCalendar);
   };

   const returnToToday = () => {
      handleDateChange(todayDate);
      var todaydateobject = new DateObject(todayDate);
      var todaydateformatted = todaydateobject.format("YYYY-MM-DD");
   }

   const previousDay = () => {
      // Subtract one day from the current date
      let previousday = new Date(showingResultsForDate);
      previousday.setDate(showingResultsForDate.getDate() - 1);

      // Format the date as YYYY-MM-DD
      let year = previousday.getFullYear();
      let month = ('0' + (previousday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let day = ('0' + previousday.getDate()).slice(-2);

      let previousdayDate = `${year}-${month}-${day}`;
      previousdayDate = convertDateStringToEST(previousdayDate);

      if (previousdayDate.getDay() === 0) //sunday
      {
         getPreviousWeekDates(previousdayDate);
      }

      setShowingResultsForDate(previousdayDate);
      handleDateChange(previousdayDate);
   }

   const previousWeek = () => {
      // Subtract one day from the current date
      let previousweek = new Date(showingResultsForDate);
      previousweek.setDate(showingResultsForDate.getDate() - 7);

      // Format the date as YYYY-MM-DD
      let year = previousweek.getFullYear();
      let month = ('0' + (previousweek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let day = ('0' + previousweek.getDate()).slice(-2);

      let previousweekDate = `${year}-${month}-${day}`;
      previousweekDate = convertDateStringToEST(previousweekDate);
      if(previousweekDate.getDay() === 0) //sunday
      {
         getPreviousWeekDates(previousweekDate);
      }

      setShowingResultsForDate(previousweekDate);
      handleDateChange(previousweekDate);
   }

   const nextDay = () => {
      // Format the date as YYYY-MM-DD
      let year = showingResultsForDate.getFullYear();
      let month = ('0' + (showingResultsForDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let day = ('0' + showingResultsForDate.getDate()).slice(-2);

      let showingResultsForDateformatted = `${year}-${month}-${day}`;
      var nextdayvalnew = caliculateNextDay(showingResultsForDateformatted, 1);
      // Create a Date object for the given date
      let date = new Date(showingResultsForDate);

      // Increment the date by one day
      date.setDate(date.getDate() + 1);

      // Format the date as a string in YYYY-MM-DD format
      let nextDay = date.toISOString().split('T')[0];
      var myenddate = new Date(todayDate);

      let todayDateyear = todayDate.getFullYear();
      let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let todayDateday = ('0' + todayDate.getDate()).slice(-2);

      let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

      var mynextday = convertDateStringToEST(nextDay);
      if(new Date(nextdayvalnew) <= new Date(todatedateformattedval)){
         handleDateChange(convertDateStringToEST(new Date(nextdayvalnew)));
      }
      else{
         
      }
   }

   const nextWeek = () => {
      // Subtract one day from the current date
      let nextweek = new Date(showingResultsForDate);
      nextweek.setDate(showingResultsForDate.getDate() + 7);

      // Format thjhhe date as YYYY-MM-DD
      let year = nextweek.getFullYear();
      let month = ('0' + (nextweek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let day = ('0' + nextweek.getDate()).slice(-2);

      let nextweekDate = `${year}-${month}-${day}`;
      var mynextweek = convertDateStringToEST(nextweekDate);
      if(mynextweek.getDay() === 1) //monday
      {
         getNextWeekDates(mynextweek);
      }
      var myenddate = new Date(todayDate); // Outputs: YYYY-MM-DD
      myenddate.setHours(0, 0, 0, 0);
      mynextweek.setHours(0, 0, 0, 0);

      if(mynextweek <= myenddate){
         setShowingResultsForDate(new Date(nextweekDate));
         handleDateChange(new Date(nextweekDate));
      }
      else{
      }
   }

   const getTimesheetResults = (getfordate) => {
      setShowingResultsForDate(convertDateStringToEST(getfordate));
      handleDateChange(convertDateStringToEST(getfordate));
   }

   const caliculateNextDay = (mydate, adddays) => {
      // Validate and parse the date string
      const dateParts = mydate.split('-');
      if (dateParts.length === 3) {
         const [year, month, day] = dateParts.map(Number);

         // Create a Date object in UTC
         const date = new Date(Date.UTC(year, month - 1, day));

         // Check if the Date object is valid
         if (!isNaN(date.getTime())) {
            // Add one day to the date
            date.setUTCDate(date.getUTCDate() + adddays);

            // Format the new date to YYYY-MM-DD
            const nextDay = date.toISOString().split('T')[0];
            return nextDay; // Outputs "2024-08-04"
         } else {
         }
      } else {
      }
   }

   const userDisplay = (formatdisplaydate) => {
      const inputDate = formatdisplaydate;

      // Create a Date object from the input date
      const date = new Date(inputDate + 'T00:00:00Z'); // Adding 'T00:00:00Z' to ensure it's treated as UTC

      // Extract day, month, and year
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getUTCFullYear();

      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate; // Output: 06-08-2024
   }

   const userDisplayMMDDYY = (formatdisplaydate) => {
      const inputDate = formatdisplaydate;

      // Create a Date object from the input date
      const date = new Date(inputDate + 'T00:00:00Z'); // Adding 'T00:00:00Z' to ensure it's treated as UTC

      // Extract day, month, and year
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getUTCFullYear();

      const formattedDate = `${month}-${day}-${year}`;
      return formattedDate; // Output: 06-08-2024
   }

   const getFormatCalendarDate = (calendarDate) => {
      if (calendarDate === "") {
         return "";
      }

      var daten = new DateObject(calendarDate);
      var daten1 = daten.format("dddd, DD MMM");
      var selecteddate = daten.format('DD');

      var today = new DateObject(todayDate);
      var dd = today.format('DD');
      if(new Date(todayDate).setHours(0, 0, 0, 0) === new Date(calendarDate).setHours(0, 0, 0, 0)){
         showReturntoToday = 0;
      } else {
         showReturntoToday = 1;
      }
      return daten1;
   }

  function getFullWeekTimesInfo(useridval, mondayDate, sundayDate){
      const requestObject =
      {
         "week_start_date": mondayDate,
         "week_end_date": sundayDate,
         "user_id": useridval
      }
      
      caliculateWeekLimit(mondayDate, sundayDate);
      var accessTokenIn = sessionStorage.getItem("accToken");
      services.getFullWeekTimesInfo(requestObject).then((res) => {
         if(res.data){
            setTotalWeekTime(res.data.total_duration);
            setDateWiseTimes(res.data.days);
         }
      });
   }

   function getTimesheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
      setClickedButtonIndex(requestdate);
      if(isEmpty(weekStartDate)){
         const givenDate = new Date(requestdate);
         const dayOfWeek1 = givenDate.getUTCDay();
         var mydateval = [];
         // Calculate the start of the week (Monday)
         const startOfWeek = new Date(givenDate);
         startOfWeek.setUTCDate(givenDate.getUTCDate() - dayOfWeek1 + 1);
         startOfWeek.setUTCHours(0, 0, 0, 0);

         // Get the dates for the entire week
         for (let i = 0; i < 7; i++) {
            const currentDay = new Date(startOfWeek);
            currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
            mydateval.push(formatDate(currentDay));
         }
         setWeekStartDate(mydateval[0]);
         setWeekEndDate(mydateval[6]);
      }
      var datetolocal = new DateObject(requestdate);
      var storetolocal = datetolocal.format("dddd, DD MMM");
      localStorage.setItem('itemforDate', storetolocal);
      var projectstatus = '';
      
      setShowLoader(1);
      
      services.getAllTimeSheetEntrieswithTotalTime(clientId, projectId, projectstatus, weekTimeEntryId, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {

         setTotalEntryTime(res.data.data.totalTime);
         setTotalBillableTime(res.data.data.billableTime);
         setTotalNonBillableTime(res.data.data.unbillableTime);
         setTotalStatus(res.data.data.consolidatedStatus);

            if(res.data.data.timeEntries === null){
               setShowTimeEtriesList([]);
            }
            else {
               var fullentries = res.data.data.timeEntries;

               for(var i=0; i< fullentries.length; i++){
                  if(fullentries[i].is_holiday === 1 || fullentries[i].is_leave === 1 || fullentries[i].is_comp_off === 1){
                     setTimesheetFreeze(true);
                  }
               }
               setShowTimeEtriesList(fullentries);
            }
            setShowLoader(0);
      });
   }
const [disabledNewEntries, setDisabledNewEntries] = useState(false);
   function getTimesheetEntriesForWeek(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDate, weekEndDate) {
      setShowWeekTimeEtriesList([]);
      setClickedButtonIndex(requestdate);
      var datetolocal = new DateObject(requestdate);
      var storetolocal = datetolocal.format("dddd, DD MMM");
      localStorage.setItem('itemforDate', storetolocal);
      var projectstatus = '';
      
      setShowLoader(1);
      
      services.getAllTimeSheetEntriesForWeek(clientId, projectId, projectstatus, weekTimeEntryId, userId, requestdate, weekStartDate, weekEndDate).then((res) => {
         setTotalEntryTimeWeek(res.data.data.totalTotalTime);
         setTotalBillableTimeWeek(res.data.data.totalBillableTime);
         setTotalNonBillableTimeWeek(res.data.data.totalUnbillableTime);

         setTotalStatusWeek(res.data.data.consolidatedStatus);
            if(res.data.data.consolidatedStatus === 'Submitted' || res.data.data.consolidatedStatus === 'Approved'){
               setDisabledNewEntries(true);
            } else {
               setDisabledNewEntries(false);
            }
         
            if(res.data.data.projects === null){
               setShowWeekTimeEtriesList([]);
            }
            else {
               var converteddata = transformObject(res.data);
               setShowWeekTimeEtriesList(converteddata.data.projects);
               setTimesheetStatusHolidays(res.data.data.holidays);
               setWeekStartDate(weekStartDate);
               setWeekEndDate(weekEndDate);
            }
            setShowLoader(0);
      });
   }

   function transformObject(obj) {
      obj.data.projects.map(project => {
         const billableTimeEntries = [];
         const unbillableTimeEntries = [];

         project.tasks.forEach(task => {
            if (task.billableEntries.length > 0) {
               billableTimeEntries.push(
                  {
                     taskId: task.taskId,
                     taskName: task.taskName,
                     sprint: task.sprint,
                     taskBillableTime: task.taskBillableTime,
                     taskUnbillableTime: task.taskUnbillableTime,
                     taskTotalTime: task.taskTotalTime,
                     timeentries: task.billableEntries.map(entry => ({
                     id: entry.id,
                     day: entry.day,
                     duration: entry.duration,
                     is_billable: 1,
                     clientId: entry.clientId,
                     projectId: entry.projectId,
                     taskId: entry.taskId,
                     time_sheet_status: entry.time_sheet_status,
                     entry_created_from_day_or_week: entry.entry_created_from_day_or_week,
                     time_entry_description: entry.time_entry_description,
                     userId: entry.userId,
                     is_holiday: entry.is_holiday,
                     is_leave: entry.is_leave,
                     is_comp_off: entry.is_comp_off,
                     group_week_entry_sequence: entry.group_week_entry_sequence,
                     week_time_entry_id: entry.week_time_entry_id
                     }))
                  }
               );
            }
            if (task.unbillableEntries.length > 0) {
               unbillableTimeEntries.push({
                  taskId: task.taskId,
                  taskName: task.taskName,
                  sprint: task.sprint,
                  taskBillableTime: task.taskBillableTime,
                  taskUnbillableTime: task.taskUnbillableTime,
                  taskTotalTime: task.taskTotalTime,
                  timeentries: task.unbillableEntries.map(entry => ({
                     id: entry.id,
                     day: entry.day,
                     duration: entry.duration,
                     is_billable: 0,
                     clientId: entry.clientId,
                     projectId: entry.projectId,
                     taskId: entry.taskId,
                     time_sheet_status: entry.time_sheet_status,
                     entry_created_from_day_or_week: entry.entry_created_from_day_or_week,
                     time_entry_description: entry.time_entry_description,
                     userId: entry.userId,
                     is_holiday: entry.is_holiday,
                     is_leave: entry.is_leave,
                     is_comp_off: entry.is_comp_off,
                     group_week_entry_sequence: entry.group_week_entry_sequence,
                     week_time_entry_id: entry.week_time_entry_id
                  }))
              });
            }
         });
         project.billableTimeEntries=billableTimeEntries;
         project.unbillableTimeEntries=unbillableTimeEntries;
      });
      return obj;
  }

   function getPreviousWeekDates(sundayDate) {
      // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
      let sunDay = sundayDate.getDay();

      // Adjust currentDay to use 0 for Monday, 1 for Tuesday, ..., 6 for Sunday
      let adjustedDay = (sunDay + 6) % 7;

      // Calculate the start and end dates of the week
      let startOfWeek = new Date(sundayDate);
      let endOfWeek = new Date(sundayDate);

      // Adjust the start date to the previous Monday
      startOfWeek.setDate(sundayDate.getDate() - adjustedDay);

      // Adjust the end date to the next Sunday
      endOfWeek.setDate(sundayDate.getDate() + (6 - adjustedDay));

      // Format the dates as YYYY-MM-DD
      let startYear = startOfWeek.getFullYear();
      let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let startDay = ('0' + startOfWeek.getDate()).slice(-2);

      let endYear = endOfWeek.getFullYear();
      let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
      let endDay = ('0' + endOfWeek.getDate()).slice(-2);

      let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
      let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

      setWeekStartDate(weekStartDate);
      setWeekEndDate(weekEndDate);
      getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
   }

   function getNextWeekDates(mondayDate) {

      // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
      let monday = mondayDate.getDay();

      // Adjust currentDay to use 0 for Monday, 1 for Tuesday, ..., 6 for Sunday
      let adjustedDay = (monday - 1) % 7;

      // Calculate the start and end dates of the week
      let startOfWeek = new Date(mondayDate);
      let endOfWeek = new Date(mondayDate);

      // Adjust the start date to the previous Monday
      startOfWeek.setDate(mondayDate.getDate() - adjustedDay);

      // Adjust the end date to the next Sunday
      endOfWeek.setDate(mondayDate.getDate() + (6 - adjustedDay));

      // Format the dates as YYYY-MM-DD
      let startYear = startOfWeek.getFullYear();
      let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let startDay = ('0' + startOfWeek.getDate()).slice(-2);

      let endYear = endOfWeek.getFullYear();
      let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
      let endDay = ('0' + endOfWeek.getDate()).slice(-2);

      let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
      let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

      setWeekStartDate(weekStartDate);
      setWeekEndDate(weekEndDate);
      getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
   }

   function getWeekTimeEntryId(status, userid, dateval) {
      // Create a new Date object for today's date
      let today = dateval;
      // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
      let currentDay = today.getDay();

      // Adjust currentDay to use 0 for Monday, 1 for Tuesday, ..., 6 for Sunday
      let adjustedDay = (currentDay + 6) % 7;

      // Calculate the start and end dates of the week
      let startOfWeek = getCurrentDateInEST(new Date(today));
      let endOfWeek = getCurrentDateInEST(new Date(today));

      // Adjust the start date to the previous Monday
      startOfWeek.setDate(today.getDate() - adjustedDay);

      // Adjust the end date to the next Sunday
      endOfWeek.setDate(today.getDate() + (6 - adjustedDay));

      // Format the dates as YYYY-MM-DD
      let startYear = startOfWeek.getFullYear();
      let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let startDay = ('0' + startOfWeek.getDate()).slice(-2);

      let endYear = endOfWeek.getFullYear();
      let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
      let endDay = ('0' + endOfWeek.getDate()).slice(-2);

      let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
      let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

      const givenDate = new Date(today);
      const dayOfWeek1 = givenDate.getUTCDay();
      var mydateval = [];
      // Calculate the start of the week (Monday)
      const startOfWeek1 = new Date(givenDate);
      startOfWeek1.setUTCDate(givenDate.getUTCDate() - dayOfWeek1 + 1);
      startOfWeek1.setUTCHours(0, 0, 0, 0);

      // Get the dates for the entire week
      for (let i = 0; i < 7; i++) {
         const currentDay = new Date(startOfWeek1);
         currentDay.setUTCDate(startOfWeek1.getUTCDate() + i);
         mydateval.push(formatDate(currentDay));
      }

      setWeekStartDate(mydateval[0]);
      setWeekEndDate(mydateval[6]);
   }

   const [loggedUser, setLoggedUser] = useState('');

   const handleAddTimeTrigger = (data) => {
      // Create a mock event object
      const mockEvent = {
         target: {
            checked: true // or false, depending on what you want to simulate
         }
      };
      // Call the function with the mock event
      handleDayWeekChange(mockEvent);
      // Perform any action needed on the main page
   };

   useEffect(() => {
         var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
         if(!isEmpty(loggedUserId)){
            $('#weekhome').hide();
            $('#timeFormatvalidationMessage').hide();
            $('#weekupdatesuccessMessage').hide();
            var reqdate = getParameterByName('req');
         
            if(!isEmpty(reqdate)){
               var newdaten = new DateObject(reqdate);
            } else {
               var newdaten = new DateObject(date);
            }

            var myrequestdate = newdaten;
            var timesheetreqdate = new Date(myrequestdate);

            setDate(myrequestdate);
            handleDateChange(timesheetreqdate);//convert
            var reqview = getParameterByName('view');
            if(!isEmpty(reqview)){
               handleDayWeekChangeFromUnsubmitted(reqview);
            } else {
               getWeekTimeEntryId('active', '', timesheetreqdate);
            }
         } else {
            navigate('/login');
         }
   }, []);

   const handleDayWeekChangeFromUnsubmitted = (tabview) => {
      if(tabview === 'week'){
         setDayWekkIsChecked(true);
      }else{
         setDayWekkIsChecked(false);
      }

      var myreqdate = getParameterByName('req');
      var myreqenddate = getParameterByName('endreq');
      
      if(tabview === 'week'){
         caliculateWeekLimit(myreqdate, myreqenddate);
         setDayWeekToggle('week');
         $('#dayhome').hide();
         getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', myreqdate, myreqenddate);
         getTimesheetComments(myreqdate, myreqenddate, useridval);
         $('#weekhome').show();
      } else {
         setDayWeekToggle('day');
         setDate(myreqdate);
         getTimesheetEntries('', '', '', '', '', '', useridval, myreqdate, '', '');
         $('#weekhome').hide();
         $('#dayhome').show();
      }
   };

   const isEmpty = (value) => {
      return value === null || value === '';
   }

   const handleCommentDatesDisplayFormat = (datetoformat) => {
      var displaydate = new DateObject(datetoformat);
      return displaydate.format("MMM DD, YYYY, HH:MM");
  };

   const handleDayWeekChange = (event) => {
      setDayWekkIsChecked(!dayWekkIsChecked);
      setShowingResultsForDate(convertDateStringToGMT(weekStartDate));
      if(event.target.checked){
         caliculateWeekLimit(weekStartDate, weekEndDate);
         setDayWeekToggle('week');
         $('#dayhome').hide();
         getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekStartDate, weekEndDate);
         $('#weekhome').show();
      } else {
         setDayWeekToggle('day');
         setDate(weekStartDate);
         getTimesheetEntries('', '', '', '', '', '', useridval, weekStartDate, '', '');
         $('#weekhome').hide();
         $('#dayhome').show();
      }
   };

   const caliculateWeekLimit = (date1, date2) => {
      const todayOfWeek = todayDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      // Create a Date object from the given date string
      const todaynew = new Date(todayDate);

      // Get the year, month, and day
      const todayyear = todaynew.getFullYear();
      const todaymonth = String(todaynew.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const todayday = String(todaynew.getDate()).padStart(2, '0');

      // Format the date as YYYY-MM-DD
      const todayformattedDate = `${todayyear}-${todaymonth}-${todayday}`;

      //console.log(todayformattedDate);  // Output: 2024-08-03

      // Create Date objects for the given dates
      const startDate = new Date(date1);
      const endDate = new Date(date2);
      const checkDate = new Date(todayformattedDate);

      // Check if the checkDate is between startDate and endDate
      if (checkDate >= startDate && checkDate <= endDate) {
         setshowReturntoWeek(0);
      } else {
         setshowReturntoWeek(1);
      }

      var strtdate = new DateObject(date1);
      var dateweek1 = strtdate.format("DD MMM");

      var enddate = new DateObject(date2);
      var dateweek2 = enddate.format("DD MMM, YYYY");
      setWeekLimitDates(dateweek1 + ' - ' + dateweek2);
   }

   const handleWeekDatesDisplayFormat = (datetoformat) => {
      var displaydate = new DateObject(datetoformat);
      return displaydate.format("DD MMM");
   };

   const [selectedProject, setSelectedProject] = useState(null);
   const [selectedClient, setSelectedClient] = useState(null);
   const [selectedTask, setSelectedTask] = useState(null);
   const [selectedBillable, setSelectedBillable] = useState(null);
   const [selectedEntryResult, setSelectedEntryResult] = useState([]);

   const [selectedDateForTimeEntry, setSelectedDateForTimeEntry] = useState(null);
   const [selectedStartDateForTimeEntry, setSelectedStartDateForTimeEntry] = useState(null);
   const [selectedEndDateDateForTimeEntry, setSelectedEndDateForTimeEntry] = useState(null);
   const [selectedTimeEntryIdForEdit, setSelectedTimeEntryIdForEdit] = useState(null);

   const handleToDisplayDescription = (id, day, projectname, clientname, taskname, billable) => {
      setSelectedProject(projectname);
      setSelectedClient(clientname);
      setSelectedTask(taskname);
      setSelectedBillable(billable);
      getMyTimesheetEntriesWithTaskId('', '', id, '', '', '', useridval, day, '', '');
   };

   const handleToDisplayWeekDescription = (id, day1, day2, projectname, clientname, taskname, billable) => {
      setSelectedProject(projectname);
      setSelectedClient(clientname);
      setSelectedTask(taskname);
      setSelectedBillable(billable);
      getMyTimesheetEntriesWithTaskId('', '', id, '', billable, '', useridval, '', day1, day2);
   }

   const [timesheetConsolidatedStatus, setTimesheetConsolidatedStatus] = useState('');
  const handleAddTimeEntryDay = (day, weekdaystart, weekdayend, timesheetstatus) => {
      $("#entryform").trigger("reset");
      //console.log(day+' *** '+weekdaystart+' **** '+weekdayend);
      var newdaten1 = new DateObject(day);
      var myrequestdate1 = newdaten1.format("YYYY-MM-DD");
      //console.log(myrequestdate1);
      setSelectedDateForTimeEntry(myrequestdate1);
      setSelectedStartDateForTimeEntry(weekdaystart);
      setSelectedEndDateForTimeEntry(weekdayend);
      setSelectedTimeEntryIdForEdit(null);
      setTimesheetConsolidatedStatus(timesheetstatus);
   }

   const handleAddTimeEntryWeek = (day1, day2, timesheetstatus) => {
      //console.log(day1+' & '+day2);
      setSelectedDateForTimeEntry(null);
      setSelectedStartDateForTimeEntry(day1);
      setSelectedEndDateForTimeEntry(day2);
      setSelectedTimeEntryIdForEdit(null);
      setTimesheetConsolidatedStatus(timesheetstatus);
   }

   function getMyTimesheetEntriesWithTaskId(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
      services.getAllTimeSheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {
         if (res.data.type === 'success') {
            setSelectedEntryResult(res.data.data);
         }
      });
   }

   const [commentsData, setCommentsData] = useState([]);
    function getTimesheetComments(weekStartDateval, weekEndDateval, usertimesheetval) {
        services.getTimesheetComments(weekStartDateval, weekEndDateval, usertimesheetval).then((res) => {
            //console.log(res.data);
           if (res.data.type === 'success') {
                if(res.data.data !== null)
                    setCommentsData(res.data.data);
           }
        });
     }

   const getProjectTotalTime = (weekday, projectid) => {
      var mylist = showWeekTimeEtriesList;
      for (var i = 0; i < mylist.length; i++) {
         if (mylist[i].projectId === projectid) {
            var myentriestime = mylist[i].dayWiseTotalTime;

            for (var j = 0; j < myentriestime.length; j++) {
               if (myentriestime[j].day === weekday) {
                  return (myentriestime[j].total_duration).slice(0, -3);
               }
            }
         }
      }
   }

   const [leavesisChecked, setLeavesIsChecked] = useState(false);
   const handleLeavesPanel = () => {
      setLeavesIsChecked(!leavesisChecked);
   }

   const [holidaysisChecked, setHolidaysIsChecked] = useState(false);
   const handleHolidaysPanel = () => {
      setHolidaysIsChecked(!holidaysisChecked);
   }

   const [compoffsisChecked, setCompoffsIsChecked] = useState(false);
   const handleCompoffPanel = () => {
      setCompoffsIsChecked(!compoffsisChecked);
   }

   const disabledstat = 'disabled';
   const checkDisablingStatus = (datevalue) => {
      for (var i = 0; i < dateWiseTimes.length; i++) {
         if (new Date(dateWiseTimes[i].day).setHours(0, 0, 0, 0) === new Date(datevalue).setHours(0, 0, 0, 0)) {
            var durationval = parseInt((dateWiseTimes[i].total_duration).replace(/:/g, ''));
            if (durationval <= 0) {
               return 0;
            }
            else {
               return 1;
            }
         }
      }
   }

   const checkCompoffDisablingStatus = (datevalue) => {
      for (var i = 0; i < dateWiseTimes.length; i++) {
         if (new Date(dateWiseTimes[i].day).setHours(0, 0, 0, 0) === new Date(datevalue).setHours(0, 0, 0, 0)) {
            var durationval = parseInt((dateWiseTimes[i].total_duration).replace(/:/g, ''));
            if (durationval > 0 ) {
               return 0;
            }
            else {
               return 1;
            }
         }
      }
   }

   function formatDate(date) {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

   const deleteTimeEntryByProject = (projectid, weekday1) => {
      /*var weekdaysfull = [];
      var myweekday1 = new Date(weekday1);
      for (let i = 0; i < 7; i++) {
         const thisweekDate1 = new Date(myweekday1);
         thisweekDate1.setDate(myweekday1.getDate() + i);
         var dateobjectval1 = new DateObject(thisweekDate1);
         weekdaysfull.push(dateobjectval1.format('YYYY-MM-DD'));
      } */

      var weekdaysfull1 = [];
      const givenDate = new Date(weekday1);
      const dayOfWeek = givenDate.getUTCDay();

      // Calculate the start of the week (Monday)
      const startOfWeek = new Date(givenDate);
      startOfWeek.setUTCDate(givenDate.getUTCDate() - dayOfWeek + 1);
      startOfWeek.setUTCHours(0, 0, 0, 0);

      // Get the dates for the entire week
      for (let i = 0; i < 7; i++) {
          const currentDay = new Date(startOfWeek);
          currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
          weekdaysfull1.push(formatDate(currentDay));
      }

      //console.log(weekdaysfull);

      //console.log(weekdaysfull1);// return false;
      const requestObject =
      {
         "projectId": projectid,
         "userId": useridval,
         "days": weekdaysfull1
      }
      var accessTokenIn = sessionStorage.getItem("accToken");
      // const requestOptions = {
      //    method: 'DELETE',
      //    headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': "Bearer " + accessTokenIn
      //    },
      //    body: JSON.stringify(requestObject)
      // };
      services.deleteTaskEntryByProjectId(requestObject).then((res) => {
         if (res.type === 'success') {
            getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekStartDate, weekEndDate);
            //console.log('7');
            getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
         }
      });
   }

   const [disableOnApproval, setdisableOnApproval] = useState(false);
   const submitTimesheetforApproval = (weekday1, weekday2) => {
      
      const formElements = editFormRef.current.elements;
      const values = []; const ids = []; var leavestat = []; var holidaystat = []; var compoffstat = [];
      for (let i = 0; i < formElements.length; i++) {
         if (formElements[i].name === 'entrydurationeditval') {
            values.push(formElements[i].value);
            ids.push(formElements[i].id);
         }

         if(formElements[i].name === 'leavescheckboxes'){
            leavestat.push(formElements[i].checked ? 1 : 0);
         }

         if(formElements[i].name === 'holidayscheckboxes'){
            holidaystat.push(formElements[i].checked ? 1 : 0);
         }

         if(formElements[i].name === 'compoffcheckboxes'){
            compoffstat.push(formElements[i].checked ? 1 : 0);
         }
      }

      var validtimes = 0;
      if(validtimes === 1){
         $('#timeFormatvalidationMessage').show();
                return false;
      } else {
         $('#timeFormatvalidationMessage').hide();
         var myupdate_create_entry = [];
         setShowLoader(1);
         for(var i=0; i<values.length; i++){
            if(!isEmpty(values[i])){

               var timeValEntered = (values[i]).replace(":", "");
               let timeString = timeValEntered.toString().padStart(4, '0');

               // Extract hours and minutes
               var hours = timeString.slice(0, 2);
               var minutes = timeString.slice(2);

               // Format time as HH:mm
               var durationvaltoInsert = `${hours}:${minutes}`;

               var entryinfo = ids[i];
               var myentrysplitinfo = entryinfo.split('_');
               var entryproj = parseInt(myentrysplitinfo[0]);
               var entrytask = parseInt(myentrysplitinfo[1]);
               var entryday = myentrysplitinfo[2];
               var entrybillable = parseInt(myentrysplitinfo[3]);

               for(var p=0; p< showWeekTimeEtriesList.length; p++){
                  //loop through projects
                  if(showWeekTimeEtriesList[p].projectId === entryproj){
                     var entryclient = showWeekTimeEtriesList[p].clientId;
                     var myupdateentry = '';
                     if(entrybillable === 1){
                        var taskCategory = showWeekTimeEtriesList[p].billableTimeEntries;
                     }
                     if(entrybillable === 0){
                        var taskCategory = showWeekTimeEtriesList[p].unbillableTimeEntries;
                     }
                     for(var t=0; t< taskCategory.length; t++){
                        if(taskCategory[t].taskId === entrytask){
                           var entries = taskCategory[t].timeentries;
                           for(var e=0; e< entries.length; e++){
                              if(new Date(entries[e].day).setHours(0, 0, 0, 0) === new Date(entryday).setHours(0, 0, 0, 0)){
                                 var myupdateentry = {
                                    "client_id": entries[e].clientId,
                                    "project_id": entries[e].projectId,
                                    "task_id": entries[e].taskId,
                                    "time_entry_description": entries[e].time_entry_description,
                                    "duration": values[i],
                                    "user_id": entries[e].userId,
                                    "day": entries[e].day,
                                    "is_billable": entries[e].is_billable,
                                    "week_time_entry_id": entries[e].week_time_entry_id,
                                    "is_holiday": entries[e].is_holiday,
                                    "is_leave": entries[e].is_leave,
                                    "is_comp_off": entries[e].is_comp_off,
                                    "time_sheet_status": entries[e].time_sheet_status,
                                    "entry_created_from_day_or_week": entries[e].entry_created_from_day_or_week,
                                    "group_week_entry_sequence": entries[e].group_week_entry_sequence,
                                    "id": entries[e].id
                                 }
                              }
                           }
                        }
                     }
                  }
               }
               if(isEmpty(myupdateentry)){
                  var myupdateentry = {
                     "client_id": entryclient,
                     "project_id": entryproj,
                     "task_id": entrytask,
                     "time_entry_description": "",
                     "duration": values[i],
                     "user_id": useridval,
                     "day": entryday,
                     "is_billable": entrybillable,
                     "week_time_entry_id": 1,//need to handle service
                     "is_holiday": 0,
                     "is_leave": 0,
                     "is_comp_off": 0,
                     "time_sheet_status": totalStatusWeek,
                     "entry_created_from_day_or_week": "week",
                     "group_week_entry_sequence": 1
                  }
               }
               myupdate_create_entry.push(myupdateentry);
            }
         }
   console.log(myupdate_create_entry); //return false;
         var accessTokenIn = sessionStorage.getItem("accToken");
         // const myrequestOptions = {
         //    method: 'POST',
         //    headers: {
         //       'Content-Type': 'application/json',
         //       'Authorization': "Bearer " + accessTokenIn
         //    },
         //    body: JSON.stringify(myupdate_create_entry)
         // };
         services.createOrUpdateEntriesonWeek(myupdate_create_entry).then((res) => {
            if (res.type === 'success') {
               var weekdaysfull = [];
               var myweekday1 = new Date(weekday1);
               for (let i = 0; i < 7; i++) {
                  const thisweekDate1 = new Date(myweekday1);
                  thisweekDate1.setDate(myweekday1.getDate() + i);
                  var dateobjectval1 = new DateObject(thisweekDate1);
                  weekdaysfull.push(dateobjectval1.format('YYYY-MM-DD'));
               }
               const requestObject =
               {
                  "userIds": [useridval],
                  "weekStartDate": weekday1,
                  "weekEndDate":weekday2,
                  "timeSheetStatus":"Submitted",
                  "reasonForRejection":"",
                  "approvedBy":"",
                  "rejectedBy":""
               }
               var accessTokenIn = sessionStorage.getItem("accToken");
               // const requestOptions = {
               //    method: 'PUT',
               //    headers: {
               //       'Content-Type': 'application/json',
               //       'Authorization': "Bearer " + accessTokenIn
               //    },
               //    body: JSON.stringify(requestObject)
               // };
               services.submitTimeSheetForApproval(requestObject).then((res) => {
                  if (res.type === 'success') {
                     var newdaten = new DateObject(date);
                     var myrequestdate = newdaten.format("YYYY-MM-DD");
                     getTimesheetEntries('', '', '', '', '', '', useridval, weekday1, '', '');
                     getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekday1, weekday2);
                     setdisableOnApproval(!disableOnApproval);
                     getFullWeekTimesInfo(useridval, weekday1, weekday2);
                     setShowLoader(0);
                  }
               });
            }
         });
      }


   }

   const [timeSheetEntryIdtoEdit, setTimeSheetEntryIdtoEdit] = useState(null);
   const viewTimesheetEntry = (timeentryid, dayval) => {
      setSelectedTimeEntryIdForEdit(timeentryid);
      var newdaten1 = new DateObject(dayval);
      var myrequestdate1 = newdaten1.format("YYYY-MM-DD");
      setSelectedDateForTimeEntry(myrequestdate1);
      setSelectedStartDateForTimeEntry(null);
      setSelectedEndDateForTimeEntry(null);
   }

   const getTimeEntryDateDuration = (entrybillable, weekday, projectid, taskid) => {
      for (var i = 0; i < showWeekTimeEtriesList.length; i++) {
         if (showWeekTimeEtriesList[i].projectId === projectid) {
            if(entrybillable === '1'){
               var timeentriesinfo = showWeekTimeEtriesList[i].billableTimeEntries;
            } else {
               var timeentriesinfo = showWeekTimeEtriesList[i].unbillableTimeEntries;
            }
           if(timeentriesinfo){
            for (var l = 0; l < timeentriesinfo.length; l++) {
               if(timeentriesinfo[l].taskId === taskid){
                  var mytimeentries = timeentriesinfo[l].timeentries;
                  var durationval = '00:00';
                  for(var t=0; t< mytimeentries.length; t++){
                     //console.log(mytimeentries[t].day);
                     //if (new Date(mytimeentries[t].day).setHours(0, 0, 0, 0) == new Date(weekday).setHours(0, 0, 0, 0)) {
                     //console.log(mytimeentries[t].day+' '+weekday)
                     //console.log(convertDateStringToEST(mytimeentries[t].day).setHours(0, 0, 0, 0)+' == '+convertDateStringToEST(weekday).setHours(0, 0, 0, 0) +' - '+mytimeentries[t].duration);
                     if (convertDateStringToEST(mytimeentries[t].day).setHours(0, 0, 0, 0) === convertDateStringToEST(weekday).setHours(0, 0, 0, 0)) {
                        var durationval = (mytimeentries[t].duration).slice(0, -3);
                     }
                     
                    // return durationval;
                  }
                  return durationval;
               }
            }
         }
         }
      }
   }


   const handleWeekDurationInputChange = (event) => {
      //console.log($('#'+event.target.id).val());
      var value = event.target.value;

      var newcleanedValue = value.replace(/\D/g, '');
      //console.log(newcleanedValue.length);
      // Remove non-numeric characters and restrict length to 4
       const cleanedValue = value.replace(/\D/g, '').slice(0, 4);
       if (newcleanedValue.length > 4) {
         var formattedtimeval = newcleanedValue.slice(4);
       } else{
         var formattedtimeval = '';
         if (cleanedValue === '') formattedtimeval = '';

         if (cleanedValue.length <= 2) {
           formattedtimeval = cleanedValue; // Return as is if only two digits or less
         }

         const firstPart = cleanedValue.slice(0, 2);
         const secondPart = cleanedValue.slice(2);

         formattedtimeval =  `${firstPart}:${secondPart}`;
       }


       $('#'+event.target.id).val(formattedtimeval);
  };


const disabled = 'disabled';
{/*const getLeaveStatus = (fortheday) => {
   for(var i=0; i< timesheetStatusHolidays.length; i++){
      if(new Date(timesheetStatusHolidays[i].day).setHours(0, 0, 0, 0) == new Date(fortheday).setHours(0, 0, 0, 0)){
         return timesheetStatusHolidays[i].is_leave;
      }
   }
}

const getHolidayStatus = (fortheday) => {
   for(var i=0; i< timesheetStatusHolidays.length; i++){
      if(new Date(timesheetStatusHolidays[i].day).setHours(0, 0, 0, 0) == new Date(fortheday).setHours(0, 0, 0, 0)){
         return timesheetStatusHolidays[i].is_holiday;
      }
   }
}

const getCompoffStatus = (fortheday) => {
   for(var i=0; i< timesheetStatusHolidays.length; i++){
      if(new Date(timesheetStatusHolidays[i].day).setHours(0, 0, 0, 0) == new Date(fortheday).setHours(0, 0, 0, 0)){
         return timesheetStatusHolidays[i].is_comp_off;
      }
   }
} */}

const editFormRef = useRef();
const saveValues=()=>
{
      const formElements = editFormRef.current.elements;
      const values = []; const ids = []; var leavestat = []; var holidaystat = []; var compoffstat = [];
      for (let i = 0; i < formElements.length; i++) {
         if (formElements[i].name === 'entrydurationeditval') {
         values.push(formElements[i].value);
         ids.push(formElements[i].id);
         }

         if(formElements[i].name === 'leavescheckboxes'){
            leavestat.push(formElements[i].checked ? 1 : 0);
         }

         if(formElements[i].name === 'holidayscheckboxes'){
            holidaystat.push(formElements[i].checked ? 1 : 0);
         }

         if(formElements[i].name === 'compoffcheckboxes'){
            compoffstat.push(formElements[i].checked ? 1 : 0);
         }
      }

      var validtimes = 0;
      /*for(var i=0; i< values.length; i++){
         if(!isEmpty(values[i])){
           // console.log();
            var edittimeEntered = (values[i]).replace(":", "");
            if(!isNaN(parseInt(edittimeEntered)) && parseInt(edittimeEntered) > 0){

               let hours, minutes;

               // Convert input to string and pad with leading zeros
               let timeString = edittimeEntered.toString().padStart(4, '0');

               // Extract hours and minutes
               hours = timeString.slice(0, 2);
               minutes = timeString.slice(2);

               // Format time as HH:mm
               $('#'+ids[i]).val(`${hours}:${minutes}`);

               //var devidededittime =edittimeEntered.split(':');
               //console.log(values[i]+' - '+edittimeEntered+' - '+hours+'-'+minutes);
               if(hours > 23 || minutes > 59){
                  validtimes =1;
                  $('#'+ids[i]).addClass('errorinp');
                  //console.log('Invalid');
               } else {
                  //console.log('valid');
               }
            }
            else{
               //console.log('valid');
            }
         }
      } */


      if(validtimes === 1){
         $('#timeFormatvalidationMessage').show();
                return false;
      } else {
         $('#timeFormatvalidationMessage').hide();
         var myupdate_create_entry = [];
         setShowLoader(0);
         for(var i=0; i<values.length; i++){
            if(!isEmpty(values[i])){

               var timeValEntered = (values[i]).replace(":", "");
               let timeString = timeValEntered.toString().padStart(4, '0');

               // Extract hours and minutes
               var hours = timeString.slice(0, 2);
               var minutes = timeString.slice(2);

               // Format time as HH:mm
               var durationvaltoInsert = `${hours}:${minutes}`;

               var entryinfo = ids[i];
               var myentrysplitinfo = entryinfo.split('_');
               var entryproj = parseInt(myentrysplitinfo[0]);
               var entrytask = parseInt(myentrysplitinfo[1]);
               var entryday = myentrysplitinfo[2];
               var entrybillable = parseInt(myentrysplitinfo[3]);

               for(var p=0; p< showWeekTimeEtriesList.length; p++){
                  //loop through projects
                  if(showWeekTimeEtriesList[p].projectId === entryproj){
                     var entryclient = showWeekTimeEtriesList[p].clientId;
                     var myupdateentry = '';
                     if(entrybillable === 1){
                        var taskCategory = showWeekTimeEtriesList[p].billableTimeEntries;
                     }
                     if(entrybillable === 0){
                        var taskCategory = showWeekTimeEtriesList[p].unbillableTimeEntries;
                     }
                     for(var t=0; t< taskCategory.length; t++){
                        if(taskCategory[t].taskId === entrytask){
                           var entries = taskCategory[t].timeentries;
                           for(var e=0; e< entries.length; e++){
                              if(new Date(entries[e].day).setHours(0, 0, 0, 0) === new Date(entryday).setHours(0, 0, 0, 0)){
                                 var myupdateentry = {
                                    "client_id": entries[e].clientId,
                                    "project_id": entries[e].projectId,
                                    "task_id": entries[e].taskId,
                                    "time_entry_description": entries[e].time_entry_description,
                                    "duration": values[i],
                                    "user_id": entries[e].userId,
                                    "day": entries[e].day,
                                    "is_billable": entries[e].is_billable,
                                    "week_time_entry_id": entries[e].week_time_entry_id,
                                    "is_holiday": entries[e].is_holiday,
                                    "is_leave": entries[e].is_leave,
                                    "is_comp_off": entries[e].is_comp_off,
                                    "time_sheet_status": entries[e].time_sheet_status,
                                    "entry_created_from_day_or_week": entries[e].entry_created_from_day_or_week,
                                    "group_week_entry_sequence": entries[e].group_week_entry_sequence,
                                    "id": entries[e].id
                                 }
                              }
                           }
                        }
                     }
                  }
               }
               if(isEmpty(myupdateentry)){
                  var myupdateentry = {
                     "client_id": entryclient,
                     "project_id": entryproj,
                     "task_id": entrytask,
                     "time_entry_description": "",
                     "duration": values[i],
                     "user_id": useridval,
                     "day": entryday,
                     "is_billable": entrybillable,
                     "week_time_entry_id": 1,//need to handle service
                     "is_holiday": 0,
                     "is_leave": 0,
                     "is_comp_off": 0,
                     "time_sheet_status": totalStatusWeek,
                     "entry_created_from_day_or_week": "week",
                     "group_week_entry_sequence": 1
                  }
               }
               myupdate_create_entry.push(myupdateentry);
            }
         }
   //console.log(myupdate_create_entry); //return false;
         var accessTokenIn = sessionStorage.getItem("accToken");
         // const myrequestOptions = {
         //    method: 'POST',
         //    headers: {
         //       'Content-Type': 'application/json',
         //       'Authorization': "Bearer " + accessTokenIn
         //    },
         //    body: JSON.stringify(myupdate_create_entry)
         // };
         services.createOrUpdateEntriesonWeek(myupdate_create_entry).then((res) => {
            if (res.type === 'success') {
               $("#weekupdatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                  $("#weekupdatesuccessMessage").slideUp(500);
              });
               var newdaten = new DateObject(date);
               var myrequestdate = newdaten.format("YYYY-MM-DD");
               getTimesheetEntries('', '', '', '', '', '', useridval, myrequestdate, '', '');
               getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekStartDate, weekEndDate);
               getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
               setShowLoader(1);
            }
         });
      }
}

const deleteTimeEntryForWeek = (taskid, isbillable, projectid, weekday1) => {
   var mydeleteobj = [];
   for(var p=0; p< showWeekTimeEtriesList.length; p++){
      //loop through projects
      if(showWeekTimeEtriesList[p].projectId === projectid){
         var billedcategory = showWeekTimeEtriesList[p].billableTimeEntries;
         var unbilledcategory = showWeekTimeEtriesList[p].unbillableTimeEntries;
         if(isbillable === '1'){
            for(var b=0; b< billedcategory.length; b++){
               if(billedcategory[b].taskId === taskid){
                  var entries = billedcategory[b].timeentries;
                  for(var e=0; e< entries.length; e++){
                     mydeleteobj.push(entries[e].id);
                  }
               }
            }
         } else {
            for(var u=0; u< unbilledcategory.length; u++){
               if(unbilledcategory[u].taskId === taskid){
                  var unbillentries = unbilledcategory[u].timeentries;
                  for(var f=0; f< unbillentries.length; f++){
                     mydeleteobj.push(unbillentries[f].id);
                  }
               }
            }
         }
      }
   }

   //console.log(weekday1);
   var weekdaysfull1 = [];
      const givenDate = new Date(weekday1);
      const dayOfWeek = givenDate.getUTCDay();

      // Calculate the start of the week (Monday)
      const startOfWeek = new Date(givenDate);
      startOfWeek.setUTCDate(givenDate.getUTCDate() - dayOfWeek + 1);
      startOfWeek.setUTCHours(0, 0, 0, 0);

      // Get the dates for the entire week
      for (let i = 0; i < 7; i++) {
          const currentDay = new Date(startOfWeek);
          currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
          weekdaysfull1.push(formatDate(currentDay));
      }
   //console.log(weekdaysfull1);

   var mydeleteObject= {
      "ids":mydeleteobj
  }
  var accessTokenIn = sessionStorage.getItem("accToken");
//   const myrequestOptions = {
//       method: 'DELETE',
//       headers: {
//           'Content-Type': 'application/json',
//           'Authorization': "Bearer " + accessTokenIn
//       },
//       body: JSON.stringify(mydeleteObject)
//   };
  services.deleteTimeEntriesById(mydeleteObject).then((res) => {
      if (res.type === 'success') {
         //console.log(weekdaysfull1);
         getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekdaysfull1[0], weekdaysfull1[6]);
      }
  });
}

const [timesheetListByStatus, setTimesheetListByStatus] = useState([]);
{/*function getTimesheetEntriesByStatus(statusString) {
   var requestObject = '';
   if(statusString == 'Submitted'){
      requestObject =
      {
          "userIds":[useridval],
          "timeSheetStatuses":['Submitted']
      }
   }
   if(statusString == 'Unsubmitted'){
      requestObject =
      {
         "userIds":[useridval],
         "timeSheetStatuses":['Unsubmitted', 'Rejected']
      }
   }

   var accessTokenIn=sessionStorage.getItem("accToken");
   const requestOptions = {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
           //'Authorization': "Bearer "+accessTokenIn
           //'Authorization': accessTokenIn
       },
       body: JSON.stringify(requestObject)
   };
   services.getTimeEntriesByStatus(requestOptions).then((res) => {
       setTimesheetListByStatus(res.data);
   });
}  */}

const checkdisablebutton = (weekstartdateval, addday, todaydateval) => {
   //console.log('check');
   var triggerdate = caliculateNextDay(weekstartdateval, addday);

   const todaydatevalyear = todaydateval.getFullYear();
   const todaydatevalmonth = String(todaydateval.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
   const todaydatevalday = String(todaydateval.getDate()).padStart(2, '0');

   // Format the date as YYYY-MM-DD
   const todayvalformattedDate = `${todaydatevalyear}-${todaydatevalmonth}-${todaydatevalday}`;
   //console.log(triggerdate+' - '+todayvalformattedDate)
   if (new Date(triggerdate) > new Date(todayvalformattedDate)) {
      return true;
  } else if (new Date(triggerdate) < new Date(todayvalformattedDate)) {
      return false;
  } else {
      return false;
  }


}

const [taskIdforDelete, setTaskIdforDelete] = useState(null);
const [taskBillableforDelete, setTaskBillableforDelete] = useState(null);
const [taskProjectIdforDelete, setTaskProjectIdforDelete] = useState(null);
const [taskRowDateforDelete, setTaskRowDateforDelete] = useState(null);

const deleteTimeEntryForWeekDialogue = (taskid, taskisbillable, projectid, weekday1) => {
   setTaskIdforDelete(taskid);
   setTaskBillableforDelete(taskisbillable);
   setTaskProjectIdforDelete(projectid);
   setTaskRowDateforDelete(weekday1);
   setShowPopup(true);
}

const deleteTimeEntryByProjectDialogue = (projectid, weekday1) => {
   if(!disabledNewEntries){
      setTaskProjectIdforDelete(projectid);
      setTaskRowDateforDelete(weekday1);
   
      setTaskIdforDelete(null);
      setTaskBillableforDelete(null);
      setShowPopup(true);
   } else {
      return false;
   }
   
}

const handleDeleteRow = () => {
   if(!isEmpty(taskBillableforDelete)){
      //delete task row
      deleteTimeEntryForWeek(taskIdforDelete, taskBillableforDelete, taskProjectIdforDelete, taskRowDateforDelete);

   } else {
      //deleteproject row
      deleteTimeEntryByProject(taskProjectIdforDelete, taskRowDateforDelete);
   }
   setShowPopup(false);
}

const handleDeleteEntry = () => {

}

const handleCancel = () => {
   setShowPopup(false);
}

const checkTimeFormat = (event) => {
   //console.log('here');
   //console.log(event.target.id);
   $('#'+event.target.id).addClass('errorinp');
   //console.log('checkhere');
   /*var timeEntered = formattedTimeVal2;
   if(!isNaN(parseInt(timeEntered)) && parseInt(timeEntered) > 0){
       var devidedtime =timeEntered.split(':');
       //console.log(devidedtime[0]+'-'+devidedtime[1]);
       if(devidedtime[0] > 23 || devidedtime[1] > 59){
           setTaskdurationvalidation(1);
       } else {
           setTaskdurationvalidation(0);
       }
   }
   else{
       setTaskdurationvalidation(0);
   } */
 }

 const handleTimeInputBlur2 = (event) => {
   const inputElement = event.target;
   let value = inputElement.value.trim();

   // Remove all non-digit characters except for the first colon
   value = value.replace(/[^0-9:]/g, '');

   // Allow only one colon
   const colonCount = (value.match(/:/g) || []).length;
   if (colonCount > 1) {
     value = value.split(':')[0] + ':' + value.split(':').slice(1).join('');
   }

   // Check if the value is already in a good format
   const isValidTime = (value) => {
     const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
     return !isNaN(hours) && hours >= 0 && hours <= 23 &&
            !isNaN(minutes) && minutes >= 0 && minutes <= 59 &&
            value.split(':').length === 2;
   };

   if (isValidTime(value)) {
     inputElement.value = value;
     return;
   }

   // Handle cases for incomplete or incorrect values
   if (value.length === 1 && !value.includes(':')) {
     // Case for single digit (e.g., '1'): append ':00'
     value = value + ':00';
   } else if (value.length === 2 && !value.includes(':')) {
     // Case for two digits (e.g., '10'): append ':00'
     value = value + ':00';
   } else if (value.length === 3 && value.includes(':')) {
     // Case for 'HMM': format as 'H:MM'
     value = value[0] + ':' + value.slice(1);
   } else if (value.length === 4) {
     // Case for 'HHMM': format as 'HH:MM'
     value = value.slice(0, 2) + ':' + value.slice(2);
   } else if (value.length === 5 && !value.includes(':')) {
     // Case for 'HHMM' if the value is already correct
     value = value.slice(0, 2) + ':' + value.slice(2);
   }

   // Validate and format the time
   const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
   if (isNaN(hours) || hours > 23 || isNaN(minutes) || minutes > 59) {
     value = ''; // Clear the input if the value is invalid
   } else {
     // Ensure hours and minutes are zero-padded
     value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
   }

   inputElement.value = value;
 };


const handleTimeInputBlur = (event) => {
      const inputElement = event.target;
      let value = inputElement.value.trim();

      // Remove all non-digit characters except for the first colon
      value = value.replace(/[^0-9:]/g, '');

      // Allow only one colon
      const parts = value.split(':');
      if (parts.length > 2) {
        value = parts[0] + ':' + parts.slice(1).join('');
      }

      // Validate if the time is already in the correct format
      const isValidTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return !isNaN(hours) && hours >= 0 && hours <= 23 &&
               !isNaN(minutes) && minutes >= 0 && minutes <= 59 &&
               time.split(':').length === 2;
      };

      if (isValidTime(value)) {
        inputElement.value = value;
        return;
      }

      // Handle cases for incomplete or incorrect values
      if (value.length === 1 && !value.includes(':')) {
        value += ':00';
      } else if (value.length === 2 && !value.includes(':')) {
        value += ':00';
      } else if (value.length === 3 && !value.includes(':')) {
        // New case: Handle 3-digit input like '125' as '1:25' or '300' as '03:00'
        value = value[0] + ':' + value.slice(1);
      } else if (value.length === 3 && value.includes(':')) {
        value = value[0] + ':' + value.slice(1);
      } else if (value.length === 4) {
        value = value.slice(0, 2) + ':' + value.slice(2);
      } else if (value.length === 5 && !value.includes(':')) {
        value = value.slice(0, 2) + ':' + value.slice(2);
      }

      // Validate and format the time
      let [hours, minutes] = value.split(':').map(Number);
      if (isNaN(hours) || hours > 23 || isNaN(minutes) || minutes > 59) {
        value = ''; // Clear the input if the value is invalid
      } else {
        // Ensure hours and minutes are zero-padded
        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');
        value = `${hours}:${minutes}`;
      }

      inputElement.value = value;
    };
/*
    const handleTimeInputBlurCustom = (event) => {
      let value = event.target.value;
      //alert(value);
      // Extract value using a regular expression for partial inputs
      const timeRegex = /^(\d{1,2})(?::(\d{1,2}))?$/;
      const match = timeRegex.exec(value);


      let hours = '00';
      let minutes = '00';

      if (match) {
        hours = match[1] || '00'; // Default to '00' if hours are missing
        minutes = match[2] || '00'; // Default to '00' if minutes are missing
      }

      // Corrected time value
      const correctedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

      // Update the input field directly
      event.target.value = correctedTime;

      // Optionally update the state if using controlled input
      // setTime(correctedTime);
    };
*/

//handleTimeInputBlurCustom

const handleTimeInputBlurCustom = (event) => {
   const inputElement = event.target;
   let value = inputElement.value.trim();

   // Remove all non-digit characters except for the first colon
   value = value.replace(/[^0-9:]/g, '');

   // Allow only one colon
   const colonCount = (value.match(/:/g) || []).length;
   if (colonCount > 1) {
     value = value.split(':')[0] + ':' + value.split(':').slice(1).join('');
   }

   // Check if the value is already in a good format
   const isValidTime = (value) => {
     const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
     return !isNaN(hours) && hours >= 0 && hours <= 23 &&
            !isNaN(minutes) && minutes >= 0 && minutes <= 59 &&
            value.split(':').length === 2;
   };

   if (isValidTime(value)) {
     inputElement.value = value;
     return;
   }

   // Handle cases for incomplete or incorrect values
   if (value.length === 1 && !value.includes(':')) {
     // Case for single digit (e.g., '1'): append ':00'
     value = value + ':00';
   } else if (value.length === 2 && !value.includes(':')) {
     // Case for two digits (e.g., '10'): append ':00'
     value = value + ':00';
   } else if (value.length === 3 && !value.includes(':')) {
     // Case for 'HMM' (e.g., '238'): format as '0H:MM'
     value = '0' + value[0] + ':' + value.slice(1);
   } else if (value.length === 3 && value.includes(':')) {
     // Case for 'H:MM': no change needed
   } else if (value.length === 4 && !value.includes(':')) {
     // Case for 'HHMM': format as 'HH:MM'
     value = value.slice(0, 2) + ':' + value.slice(2);
   } else if (value.length === 5 && !value.includes(':')) {
     // Case for 'HHMM' if the value is already correct
     value = value.slice(0, 2) + ':' + value.slice(2);
   }

   // Validate and format the time
   const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
   if (isNaN(hours) || hours > 23 || isNaN(minutes) || minutes > 59) {
     value = ''; // Clear the input if the value is invalid
   } else {
     // Ensure hours and minutes are zero-padded
     value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
   }

   inputElement.value = value;
 };

   return (
      <>
         <main>


            <section>
               <div class="container">
                  <h2 class="mainHeadline">Timesheet</h2>
                  <div class="tab-primary">

                     {(loggedUser.toLocaleLowerCase() === 'manager' || loggedUser.toLocaleLowerCase() === 'employee') ?
                     <TimesheetHeader />
                     : ''}

                     {/* Tab panes */}
                     <div class="tab-content ">
                        <div id="dayhome" class="tab-pane active">
                           <div class="row"><div class="col-lg-12">
                              {/* Tab panes */}
                              <div class="tab-content w-100 position-relative">
                                 <div class="tab-pane container-fluid active px-0" id="home">
                                    <div class="d-flex tab-content-bg-primary justify-content-between">
                                       <div className="d-flex align-items-center">
                                          <div class="prev-after-wrap">
                                             <a href="#" onClick={previousDay}> <span> <img src="images/left.png" alt="" /></span></a>
                                             <a href="#" onClick={nextDay}><span> <img src="images/right.png" alt="" /></span></a>
                                          </div>
                                          <div class="daytitlewrap">
                                             <span class="d-flex mt-0">
                                             {/* <img class="icon" src="images/cal-icon.png" alt=""/>*/}
                                             <div>
                                                <button onClick={toggleCalendar} style={{ background: 'none', margin:"0px", border: 'none', cursor: 'pointer' }}><img class="icon" src="images/cal-icon.png" alt="" /></button>
                                                {/*<div><Calendar onChange={handleDateChange} value={date} maxDate={new Date()} /></div> */}
                                                {showCalendar && (

                                                   <div><Calendar onChange={handleDateChange} value={date} maxDate={getCurrentDateInEST()} /></div>

                                                )}
                                             </div>
                                             <div class="d-grid align-items-center">
                                                <h2>{getFormatCalendarDate(date)} </h2>
                                                {/* {totalStatus != 'Submitted' ?
                                                <div> */}
                                                {showReturntoToday === 1 && (
                                                   <a href="#" onClick={returnToToday}><img src="images/returnArrow.png" style={{ "margin-right": "4px" }} alt="" />Return to Today</a>
                                                )}
                                                {/* </div>
                                                : '' } */}
                                             </div>

                                             {totalStatus === 'Approved' ?
                                             <div>
                                                <small class="badge rounded-pill bg-success mx-2 font-500 px-3 py-2">Approved</small>
                                             </div>
                                             : totalStatus === 'Submitted' ?
                                             <div>
                                                <small class="badge rounded-pill bg-success-orange mx-2 font-500 px-3 py-2">Pending Approval</small>
                                             </div>
                                             :'' }
                                             </span>
                                          </div>
                                       </div>
                                       <div class="dayweekswitch">
                                          <div class="d-flex align-items-center gap-2 py-2 form-switch">
                                             <label class="form-check-label" for="flexSwitchCheckDefault">Day</label>
                                             <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleDayWeekChange} checked={dayWekkIsChecked} />
                                             <label class="form-check-label" for="flexSwitchCheckDefault">Week</label>
                                          </div>
                                       </div>
                                    </div>
                                    {/* 1- Mon, 2-Tue, 3-Wed, 4-thu, 5-Fri, 6-sat, 7-sun*/}

                                    <div class="calendar-table">
                                       <button class={`ct-item ${clickedButtonIndex === weekStartDate ? 'dayactive' : ''}`} disabled={checkdisablebutton(weekStartDate, 0, todayDate)} onClick={() => getTimesheetResults(weekStartDate)}>
                                          <span>MON</span>
                                          <span class="d-flex justify-content-center">{userDisplayMMDDYY(weekStartDate)}</span>
                                          <span class="d-flex justify-content-center">
                                             {dateWiseTimes.map((data, index) => (
                                                data.day === weekStartDate ? (data.total_duration).slice(0, -3) : '' ))}
                                             <span> Hrs</span>
                                          </span>
                                          <input type="hidden" name="weekdate" id="weekdate1" value={weekStartDate} />
                                       </button>

                                          <button class={`ct-item ${clickedButtonIndex === caliculateNextDay(weekStartDate, 1) ? 'dayactive' : ''}`}
                                             disabled={checkdisablebutton(weekStartDate, 1, todayDate)}
                                             onClick={() => getTimesheetResults(caliculateNextDay(weekStartDate, 1))}>
                                             <span>TUE</span>
                                             <span class="d-flex justify-content-center">{userDisplayMMDDYY(caliculateNextDay(weekStartDate, 1))}</span>
                                             <span class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                   data.day === caliculateNextDay(weekStartDate, 1) ? (data.total_duration).slice(0, -3) : ''
                                                ))} <span> Hrs</span>
                                             </span>
                                             <input type="hidden" name="weekdate" id="weekdate2" value={caliculateNextDay(weekStartDate, 1)} />
                                          </button>

                                          <button class={`ct-item ${clickedButtonIndex === caliculateNextDay(weekStartDate, 2) ? 'dayactive' : ''}`}
                                             disabled={checkdisablebutton(weekStartDate, 2, todayDate)}
                                             onClick={() => getTimesheetResults(caliculateNextDay(weekStartDate, 2))}>
                                             <span>WED</span>
                                             <span class="d-flex justify-content-center">{userDisplayMMDDYY(caliculateNextDay(weekStartDate, 2))}</span>
                                             <span class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                   data.day === caliculateNextDay(weekStartDate, 2) ? (data.total_duration).slice(0, -3) : ''
                                                ))} <span> Hrs</span>
                                             </span>
                                             <input type="hidden" name="weekdate" id="weekdate3" value={caliculateNextDay(weekStartDate, 2)} />
                                          </button>

                                          <button class={`ct-item ${clickedButtonIndex === caliculateNextDay(weekStartDate, 3) ? 'dayactive' : ''}`} disabled={checkdisablebutton(weekStartDate, 3, todayDate)} onClick={() => getTimesheetResults(caliculateNextDay(weekStartDate, 3))}>
                                             <span>THU</span>
                                             <span class="d-flex justify-content-center">{userDisplayMMDDYY(caliculateNextDay(weekStartDate, 3))}</span>
                                             <span class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                   data.day === caliculateNextDay(weekStartDate, 3) ? (data.total_duration).slice(0, -3) : ''
                                                ))} <span> Hrs</span>
                                             </span>
                                             <input type="hidden" name="weekdate" id="weekdate4" value={caliculateNextDay(weekStartDate, 3)} />
                                          </button>

                                          <button class={`ct-item ${clickedButtonIndex === caliculateNextDay(weekStartDate, 4) ? 'dayactive' : ''}`} disabled={checkdisablebutton(weekStartDate, 4, todayDate)} onClick={() => getTimesheetResults(caliculateNextDay(weekStartDate, 4))}>
                                             <span>FRI</span>
                                             <span class="d-flex justify-content-center">{userDisplayMMDDYY(caliculateNextDay(weekStartDate, 4))}</span>
                                             <span class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                   data.day === caliculateNextDay(weekStartDate, 4) ? (data.total_duration).slice(0, -3) : ''
                                                ))} <span> Hrs</span>
                                             </span>
                                             <input type="hidden" name="weekdate" id="weekdate5" value={caliculateNextDay(weekStartDate, 4)} />
                                          </button>

                                          <button class={`ct-item ${clickedButtonIndex === caliculateNextDay(weekStartDate, 5) ? 'dayactive' : ''}`} disabled={checkdisablebutton(weekStartDate, 5, todayDate)} onClick={() => getTimesheetResults(caliculateNextDay(weekStartDate, 5))}>
                                             <span>SAT</span>
                                             <span class="d-flex justify-content-center">{userDisplayMMDDYY(caliculateNextDay(weekStartDate, 5))}</span>
                                             <span class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                   data.day === caliculateNextDay(weekStartDate, 5) ? (data.total_duration).slice(0, -3) : ''
                                                ))} <span> Hrs</span>
                                             </span>
                                             <input type="hidden" name="weekdate" id="weekdate6" value={caliculateNextDay(weekStartDate, 5)} />
                                          </button>


<button class={`ct-item ${clickedButtonIndex === caliculateNextDay(weekStartDate, 6) ? 'dayactive' : ''}`} disabled={checkdisablebutton(weekStartDate, 6, todayDate)} onClick={() => getTimesheetResults(caliculateNextDay(weekStartDate, 6))}>
                                             <span>SUN</span>
                                             <span class="d-flex justify-content-center">{userDisplayMMDDYY(caliculateNextDay(weekStartDate, 6))}</span>
                                             <span class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                   data.day === caliculateNextDay(weekStartDate, 6) ? (data.total_duration).slice(0, -3) : ''
                                                ))} <span> Hrs</span>
                                             </span>

                                             <input type="hidden" name="weekdate" id="weekdate7" value={caliculateNextDay(weekStartDate, 6)} />
                                          </button>
                                       </div>

                                       <div class="addtimewrap text-center">
                                          <div class="d-grid mt-2">
                                             <b>Add Time</b>
                                             <button type="button" onClick={() => handleAddTimeEntryDay(date, weekStartDate, caliculateNextDay(weekStartDate, 6), totalStatus)} class={`btn btn-primary ${(totalStatus === 'Submitted' || totalStatus === 'Approved') ? 'disabled' : ''}`} data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" disabled={timesheetfreeze} style={{backgroundColor:"transparent"}}><img
                                                src="images/add-time-icon.svg" alt="" /></button>

                                          </div>
                                       </div>
                                       {showTimeEtriesList.length > 0 ?

                                          <div class="mainbox">
                                             <div class="daymainwrap">
                                                {/* {showTimeEtriesList.map((data, index) => (
                                                   <div>{}
                                                      {(data.task_id != 0 && !isEmpty(data.task_id)) ?
                                                      <div class="daylist-item">
                                                         <div class="props">
                                                         <Tooltip  text="Non-Billable">
                                                            {data.is_billable == 1 ? <img src="images/billed.png" alt="" /> : <img src="images/nonbill.png" alt="" />}
                                                         </Tooltip>
                                                         </div>
                                                         <div class="list">
                                                            <div class="client d-flex gap-2 align-items-center">
                                                               <b>Client:</b>
                                                               <span class="bluetext ">{data.client.client_name}</span>
                                                            </div>
                                                            <div class="projName d-flex gap-2 align-items-center ">                                                               <strong>{data.project.name}</strong>
                                                               {!isEmpty(data.task.sprint) ? <div class="lastrongelsprint ">{data.task.sprint}</div> : ''}
                                                            </div>
                                                            <b class="taskName">{data.task.name}</b>
                                                            <div class="description">
                                                               <p>{data.time_entry_description} <a href="#" onClick={() => handleToDisplayDescription(data.task_id, data.day, data.project.name, data.client.client_name, data.task.name, data.task.is_billable)} data-bs-toggle="modal" data-bs-target="#taskdes">More</a> </p>
                                                            </div>
                                                         </div>
                                                         <div class="hours scale85">
                                                            <span class="hrtime">{(data.duration).slice(0, -3)}</span>
                                                            <span class="hrlabel">HOURS</span>
                                                         </div>
                                                         <div class="edit d-flex gap-2">

                                                            <button type="button" onClick={() => viewTimesheetEntry(data.id, date)} class={`btn ${(data.time_sheet_status == 'Submitted' || data.time_sheet_status == 'Approved') ? 'disabled' : ''}`} data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" disabled={timesheetfreeze}><img src="images/edit.png" class="px-2" alt="" />Edit</button>
                                                         </div>
                                                      </div>
                                                      : ''}
                                                   </div>

                                                ))} */}

                                             {showTimeEtriesList.map((data, index) => {
                                                // Check if the duration is not '00:00'
                                                const isVisible = (data.duration && data.duration.slice(0, -3) !== '00:00');

                                                // Only render the row if the duration is not '00:00'
                                                return isVisible ? (
                                                   <div key={index}>
                                                      <div className="daylist-item">
                                                         <div className="props">
                                                            <Tooltip text="This is a tooltip">
                                                               {data.is_billable === 1 ? <img src="images/billed.png" alt="" /> : <img src="images/nonbill.png" alt="" />}
                                                            </Tooltip>
                                                         </div>
                                                         <div className="list">
                                                            <div className="client d-flex gap-2 align-items-center">
                                                               <b>Client:</b>
                                                               <span className="bluetext ">{data.client.client_name}</span>
                                                            </div>
                                                            <div className="projName d-flex gap-2 align-items-center">
                                                               <strong>{data.project.name}</strong>
                                                               {!isEmpty(data.task.sprint) ? <div className="lastrongelsprint ">{data.task.sprint}</div> : ''}
                                                            </div>
                                                            <b className="taskName">{data.task.name}</b>
                                                            <div className="description">
                                                               <p>
                                                                  {data.time_entry_description}
                                                                  <a href="#" onClick={() => handleToDisplayDescription(data.task_id, data.day, data.project.name, data.client.client_name, data.task.name, data.task.is_billable)} data-bs-toggle="modal" data-bs-target="#taskdes" style={{marginLeft:6}}>More</a>
                                                               </p>
                                                            </div>
                                                         </div>
                                                         <div className="hours scale85">
                                                            <span className="hrtime">{data.duration.slice(0, -3)}</span>
                                                            <span className="hrlabel">HOURS</span>
                                                         </div>
                                                         <div className="edit d-flex gap-2">
                                                            <button
                                                               type="button"
                                                               onClick={() => viewTimesheetEntry(data.id, date)}
                                                               className={`btn ${(data.time_sheet_status === 'Submitted' || data.time_sheet_status === 'Approved') ? 'disabled' : ''}`}
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#exampleModal"
                                                               data-bs-whatever="@mdo"
                                                               disabled={timesheetfreeze}
                                                            >
                                                               <img src="images/edit.png" className="px-2" alt="" />Edit
                                                            </button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                ) : null;
                                             })}

                                             </div>
                                          </div>
                                          :
                                          <div class="mainbox">
                                             <div class="goodmorning ">
                                                <div class="text-center d-grid">
                                                   <img src="images/goodmorning.png" class="m-auto" alt="" />
                                                   <b>Good morning! </b>
                                                   <span> Ready for a productive day?</span>
                                                </div>
{/*
                                                <div class="or"><span>OR</span></div>

                                                <div class="leaveholiday d-flex gap-4 justify-content-center">
                                                   <div class="d-flex">
                                                      <input type="checkbox" class="form-check-input mx-2" id="dayLeaveCheck1" onClick={() => handleLeaveforToday(date)} disabled={timesheetfreeze}/> <span class="bluetext">Leave</span>
                                                   </div>
                                                   <div class="d-flex">
                                                      <input type="checkbox" class="form-check-input mx-2" id="dayHolidayCheck1" onClick={() => handleHolidayforToday(date)} disabled={timesheetfreeze}/> <span class="bluetext">Holiday</span>
                                                   </div>
                                                </div> */}
                                             </div>
                                          </div>
                                       }
                                       <div class="dayweektotal mt-3">
                                          <div class="totalday">
                                             <div class="d-flex justify-content-between p-3 align-items-center ">
                                                {showTimeEtriesList.length > 0 ?
                                                <div class="d-flex">
                                                   {/*
                                                   {parseInt((totalEntryTime).replace(/:/g, '')) == 0 ?
                                                      <div class="d-flex">
                                                         <input type="checkbox" class="form-check-input mx-2" id="dayLeaveCheck" onClick={() => handleLeaveforToday(date)} disabled={timesheetfreeze}/>
                                                         <span class="bluetext">Leave</span>
                                                         <input type="checkbox" class="form-check-input mx-2" id="dayHolidayCheck" onClick={() => handleHolidayforToday(date)} disabled={timesheetfreeze}/>
                                                         <span class="bluetext">Holiday</span>
                                                      </div>
                                                   :
                                                      <div class="d-flex">
                                                         <input type="checkbox" class="form-check-input mx-2"  id="dayCompoffCheck" onClick={() => handleCompoffforToday(date)} disabled={timesheetfreeze}/>
                                                         <span class="bluetext">Request for Comp-off</span>
                                                      </div>
                                                   } */}
                                                </div>
                                                :
                                                <div class="d-flex">

                                                </div>
                                                }


                                                <div>Total Time : <b class="mainbluetxt">{totalEntryTime} <span>Hrs</span> </b> </div>                                             </div>
                                          </div>
                                          <div class="totalweek border-top-0">
                                             <div class="d-flex justify-content-between p-3 align-items-center ">
                                                <div></div>
                                                <div>Total time for the week : <b class="mainbluetxt">{totalWeekTime.slice(0, -3)} <span>Hrs</span></b> </div>
                                             </div>
                                          </div>
                                       </div>
                                       {parseInt(totalWeekTime) > 0 ?
                                       <div class="btn-group my-4">
                                          <a href="#" onClick={() => submitTimesheetforApproval(weekStartDate, weekEndDate)} class={`btn btn-primary ${(totalStatus === 'Submitted' || totalStatus === 'Approved') ? 'disabled' : ''}`}>Submit for approval</a>
                                       </div>
                                       :
                                       <div></div>
}
                                    </div>

                                 </div>
                              </div>

                              {showPopup && (
                                    <DeleteConfirmationPopup
                                    message="Are you sure you want to Delete this entry?"
                                    onConfirm={handleDeleteEntry}
                                    onCancel={handleCancel}
                                    />
                              )}

                           </div>

                        </div>

                        <div id="weekhome" class="tab-pane active">
                           <div class="row"><div class="col-lg-12">
                              {/*} Tab panes */}
                              <form ref={editFormRef} id="editweekentries" >
                              <div class="tab-content w-100">
                                 <div class="tab-pane container-fluid active px-0" id="home">
{(!isEmpty(getParameterByName('status'))) ?
                                 <div class="d-flex justify-content-between tab-content-bg-primary position-relative" style={{"padding" : "30px 0px 0px 30px"}}>
                                       <div class="d-flex align-items-center">
                                          <a href="/unsubmitted-list">
                                             <img src="images/left.png" style={{"margin-right" : "4px"}} alt=""/>Back to UnsubmittedList Page
                                          </a>
                                       </div>
                                    </div>
                                   : '' }

                                    <div class="d-flex justify-content-between tab-content-bg-primary position-relative">
                                       <div class="d-flex align-items-center">

                                          <div class="prev-after-wrap">
                                             <a href="#" onClick={previousWeek}> <span> <img src="images/left.png" alt="" /></span></a>
                                             <a href="#" onClick={nextWeek}><span> <img src="images/right.png" alt="" /></span></a>
                                          </div>
                                          <div class="daytitlewrap ">
                                             <span class="d-flex align-items-center">
                                                <div>
                                                   <button onClick={toggleCalendar} style={{ background: 'none', margin:"0px", border: 'none', cursor: 'pointer' }}><img class="icon" src="images/cal-icon.png" alt="" /></button>
                                                   {showCalendar && ( <div><Calendar onChange={handleDateChange} value={date} maxDate={getCurrentDateInEST()} /></div> )}
                                                </div>

                                                <div class="d-grid">
                                                   <h2>Week: {weekLimitDates}</h2>
                                                   {/* {totalStatusWeek != 'Submitted' ?
                                                   <div> */}
                                                   {showReturntoWeek === 1 ?
                                                      <a href="#" onClick={returnToToday}><img src="images/returnArrow.png" style={{ "margin-right": "4px" }} alt="" />Return to this Week</a> : ''
                                                   }
                                                   {/* </div>
                                                   : '' } */}
                                                </div>
                                                
                                                {totalStatusWeek === 'Approved' ?
                                             <div>
                                                <small class="badge rounded-pill bg-success mx-2 font-500 px-3 py-2">Approved</small>
                                             </div>
                                             : totalStatusWeek === 'Submitted' ?
                                             <div>
                                                <small class="badge rounded-pill bg-success-orange mx-2 font-500 px-3 py-2">Pending Approval</small>
                                             </div>
                                             :'' }

                                             </span>
                                          </div>
                                       </div>
                                       <div class="dayweekswitch"><div class="d-flex align-items-center gap-2 py-2 form-switch">
                                          <label class="form-check-label" for="flexSwitchCheckDefault">Day</label>
                                          <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleDayWeekChange} checked={dayWekkIsChecked} />
                                          <label class="form-check-label" checked for="flexSwitchCheckDefault">Week</label>
                                       </div></div>
                                    </div>

                                       <div class="mainbox"><div class="weekcal">
                                          <div></div>
                                          <div class="weekcalmain">
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">MON</b>
                                                <small>{handleWeekDatesDisplayFormat(weekStartDate)}</small>
                                             </span>
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">tue</b>
                                                <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 1))}</small>
                                             </span>
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">wed</b>
                                                <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 2))}</small>
                                             </span>
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">thu</b>
                                                <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 3))}</small>
                                             </span>
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">fri</b>
                                                <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 4))}</small>
                                             </span>
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">sat</b>
                                                <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 5))}</small>
                                             </span>
                                             <span class="weekmainitem d-grid">
                                                <b class="text-uppercase">sun</b>
                                                <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 6))}</small>
                                             </span>
                                          </div>
                                          <div></div>
                                       </div>
                                       {showWeekTimeEtriesList.length > 0 ?
                                       <div class="accordion" id="accordionPanelsStayOpenExample ">
                                       {showWeekTimeEtriesList.map((data, index) => (
                                          <div class="accordion-item">
                                             <h2 class="accordion-header weekafterem"><button class="accordion-button px-0 position-relative weekviewlist" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse`+index} aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                                <div class="weekcallist w-100">
                                                   <div class="px-3 d-flex align-items-center gap-2"><div class="list ps-2"><div class="client d-flex gap-2 align-items-center">
                                                      <b>{data.projectName} </b>
                                                      <span class="bluetext ">({data.clientName})</span>
                                                   </div></div></div>
                                                   <div class="weekcalmain ">
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" aria-describedby="emailHelp" value={getProjectTotalTime(weekStartDate, data.projectId)} disabled />
                                                      </span>
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" value={getProjectTotalTime(caliculateNextDay(weekStartDate, 1), data.projectId)} aria-describedby="emailHelp" disabled />
                                                      </span>
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" value={getProjectTotalTime(caliculateNextDay(weekStartDate, 2), data.projectId)} aria-describedby="emailHelp" disabled />
                                                      </span>
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" value={getProjectTotalTime(caliculateNextDay(weekStartDate, 3), data.projectId)} aria-describedby="emailHelp" disabled />
                                                      </span>
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" value={getProjectTotalTime(caliculateNextDay(weekStartDate, 4), data.projectId)} aria-describedby="emailHelp" disabled />
                                                      </span>
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" value={getProjectTotalTime(caliculateNextDay(weekStartDate, 5), data.projectId)} aria-describedby="emailHelp" disabled />
                                                      </span>
                                                      <span class="weekmainitem d-grid">
                                                         <input type="text" class="form-control" value={getProjectTotalTime(caliculateNextDay(weekStartDate, 6), data.projectId)} aria-describedby="emailHelp" disabled />
                                                      </span>
                                                   </div>
                                                   <div>
                                                      <div> <b class="mainbluetxt">{(data.projectTotalTime).slice(0, -3)}</b></div>
                                                      <div class="weekclose">
                                                         <a
                                                         href="#" class={`${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : '' }`} onClick={() => deleteTimeEntryByProjectDialogue(data.projectId, weekStartDate)} disabled={disabledNewEntries}><img src="images/close.svg" alt="" /></a>
                                                         {/* {() => deleteTimeEntryByProject(data.projectId, weekStartDate)} */}
                                                      </div>
                                                   </div>
                                                </div>
                                             </button></h2>
                                             <div id={`panelsStayOpen-collapse`+index} class="accordion-collapse collapse show" data-bs-parent="#accordionPanelsStayOpenExample">
                                                <div class="accordion-body px-0 position-relative py-1">
                                                {data.billableTimeEntries.length > 0 ?
                                                   <div>
                                                   {data.billableTimeEntries.map((billabletasks, billabletaskindex) => (
                                                   <div>
                                                   {billabletasks.timeentries.length > 0 ?
                                                      <div class="weekcallist w-100">
                                                         <div class="px-3 d-flex align-items-center gap-2">
                                                            <div class="list ps-2">
                                                               <div class="projName d-flex gap-2 align-items-center my-2 ">
                                                                  <div class="props" style={{ "margin-top": "0px" }}><img src="images/billed.png" alt="" /></div>
                                                                  <strong>{billabletasks.taskName}</strong>
                                                                  <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(billabletasks.taskId, weekStartDate, caliculateNextDay(weekStartDate, 6), data.projectName, data.clientName, billabletasks.taskName, '1')}>More</a>

                                                                  {!isEmpty(billabletasks.sprint) ?
                                                                  <div class="lastrongelsprint ">{billabletasks.sprint}</div> : '' }
                                                               </div>
                                                            </div>
                                                            </div>
                                                            <div class="weekcalmain ">
                                                               <span class="weekmainitem d-grid">

                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`} aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('1', weekStartDate, data.projectId, billabletasks.taskId)}
                                                               id={data.projectId+'_'+billabletasks.taskId+'_'+weekStartDate+'_1'} name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur} /> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', weekStartDate, data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+weekStartDate+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">

                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`} aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 1), data.projectId, billabletasks.taskId)}
                                                               name="entrydurationeditval" id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 1)+'_1'} maxLength='5' onBlur={handleTimeInputBlur} /> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 1), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 1)+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">

                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`} aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 2), data.projectId, billabletasks.taskId)}
                                                               id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 2)+'_1'} name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 2), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 2)+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`} aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 3), data.projectId, billabletasks.taskId)}
                                                               id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 3)+'_1'} name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 3), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 3)+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`} onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`}  aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 4), data.projectId, billabletasks.taskId)}
                                                               id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 4)+'_1'} name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 4), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 4)+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`} onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`}  aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 5), data.projectId, billabletasks.taskId)}
                                                               id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 5)+'_1'}  name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 5), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 5)+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class={`form-control ${totalStatusWeek === 'Submitted' ? 'readonly' : ''}`}  aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 6), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 6)+'_1'}   name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 6), data.projectId, billabletasks.taskId)} id={data.projectId+'_'+billabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 6)+'_1'} name="entrydurationeditval" className={`no-icon form-control ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>
                                                         </div>
                                                         <div>
                                                            <div> <b class="mainbluetxt ">{(billabletasks.taskBillableTime).slice(0, -3)}</b></div>
                                                         </div>
                                                         <div class="entryclose">
                                                            <a
                                                            style={{
                                                               pointerEvents: (totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'none' : 'auto',
                                                               opacity: (totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 0.5 : 1
                                                             }}
                                                            href="#" class={`${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`} onClick={() => deleteTimeEntryForWeekDialogue(billabletasks.taskId, '1', data.projectId, weekStartDate)} disabled={disabledNewEntries}><img src="images/close.svg" alt="" /></a>
                                                         </div>
                                                         </div>
                                                         : '' }
                                                   </div>
                                                   ))}
                                                   </div>
                                                :  '' }

                                                {data.unbillableTimeEntries.length > 0 ?
                                                   <div>
                                                   {data.unbillableTimeEntries.map((unbillabletasks, unbillabletaskindex) => (
                                                   <div>
                                                     {unbillabletasks.timeentries.length > 0 ?
                                                         <div class="weekcallist w-100">
                                                            <div class="px-3 d-flex align-items-center gap-2">
                                                            <div class="list ps-2">
                                                               <div class="projName d-flex gap-2 align-items-center my-2 ">
                                                                  <div class="props" style={{ "margin-top": "0px" }}><img src="images/nonbill.png" alt="" /></div>
                                                                  <strong>{unbillabletasks.taskName}</strong>
                                                                  <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(unbillabletasks.taskId, weekStartDate, caliculateNextDay(weekStartDate, 6), data.projectName, data.clientName, unbillabletasks.taskName, '0')}>More</a>

                                                                  {!isEmpty(unbillabletasks.sprint) ?
                                                                  <div class="lastrongelsprint ">{unbillabletasks.sprint}</div> : '' }
                                                               </div>
                                                            </div>
                                                            </div>
                                                            <div class="weekcalmain ">
                                                            <span class="weekmainitem d-grid">

                                                               {/* <input type="text" class="form-control"      aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('0', weekStartDate, data.projectId, unbillabletasks.taskId)}
                                                               id={data.projectId+'_'+unbillabletasks.taskId+'_'+weekStartDate+'_0'} name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', weekStartDate, data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+weekStartDate+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>


                                                            </span>

                                                            <span class="weekmainitem d-grid">

                                                               {/* <input type="text" class="form-control" aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 1), data.projectId, unbillabletasks.taskId)}
                                                               id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 1)+'_0'}  name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 1), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 1)+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">

                                                               {/* <input type="text" class="form-control" aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 2), data.projectId, unbillabletasks.taskId)}
                                                               id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 2)+'_0'}   name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 2), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 2)+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class="form-control" aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 3), data.projectId, unbillabletasks.taskId)}
                                                               id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 3)+'_0'}  name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 3), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 3)+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class="form-control"  aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 4), data.projectId, unbillabletasks.taskId)}
                                                               id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 4)+'_0'}  name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 4), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 4)+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class="form-control"  aria-describedby="emailHelp"
                                                               defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 5), data.projectId, unbillabletasks.taskId)}
                                                               id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 5)+'_0'}   name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 5), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 5)+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>

                                                            <span class="weekmainitem d-grid">
                                                               {/* <input type="text" class="form-control"  aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 6), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 6)+'_0'}    name="entrydurationeditval" maxLength='5' onBlur={handleTimeInputBlur}/> */}

<input type="text" aria-describedby="emailHelp" defaultValue={getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 6), data.projectId, unbillabletasks.taskId)} id={data.projectId+'_'+unbillabletasks.taskId+'_'+caliculateNextDay(weekStartDate, 6)+'_0'} name="entrydurationeditval" className={`no-icon form-control ${totalStatusWeek === 'Submitted' ? 'disabled-link' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5"/>

                                                            </span>
                                                         </div>
                                                         <div>
                                                            <div> <b class="mainbluetxt15 ">{(unbillabletasks.taskUnbillableTime).slice(0, -3)}</b></div>
                                                         </div>
                                                         <div class="entryclose">
                                                            <a href="#" class={`${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled-link' : ''}`} onClick={() => deleteTimeEntryForWeekDialogue(unbillabletasks.taskId, '0', data.projectId, weekStartDate)} disabled={disabledNewEntries}><img src="images/close.svg" alt="" /></a>
                                                         </div>
                                                         </div>
                                                         : '' }
                                                   </div>
                                                   ))}
                                                   </div>
                                                :  '' }
                                                         </div>
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          :
                                             <div class="goodmorning ">
                                                <div class="text-center d-grid">
                                                   <img src="images/goodmorning.png" class="m-auto" alt="" />
                                                   <b>Good morning! </b>
                                                   <span> Ready for a productive week?</span>
                                                </div>
                                             </div>
                                       }
                                       </div>
                                    </div>
                                    {showWeekTimeEtriesList.length > 0 ?
                                       <div class="lhcwraper border-bottom">
                                          {/*
                                          <div class="lhc p-3">
                                             <div class="d-flex gap-2 justify-content-start">
                                                <div class="d-flex">
                                                   <input type="checkbox" class="form-check-input mx-2" id="leaves" onChange={handleLeavesPanel} />
                                                   <span class="bluetext fw-bold">Leave</span>
                                                </div>
                                                <div class="d-flex">
                                                   <input type="checkbox" class="form-check-input mx-2" id="holidays" onChange={handleHolidaysPanel} />
                                                   <span class="bluetext fw-bold">Holiday</span>
                                                </div>
                                                <div class="d-flex">
                                                   <input type="checkbox" class="form-check-input mx-2" id="compoffs" onChange={handleCompoffPanel} />
                                                   <span class="bluetext fw-bold">Comp-off</span>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="lhsbody my-2">
                                             {leavesisChecked && (
                                                <div class="weekcallist" id="leavepanel">
                                                   <span class="ps-4">Leave</span>
                                                   <span class="checkbox-list align-items-center">
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(weekStartDate) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+weekStartDate} name="leavescheckboxes" checked={getLeaveStatus(weekStartDate)}  disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+weekStartDate} checked={getLeaveStatus(weekStartDate)} name="leavescheckboxes" />}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 1)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 1)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 1))} disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 1)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 1))}/>}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 2)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 2)} name="leavescheckboxes" disabled checked={getLeaveStatus(caliculateNextDay(weekStartDate, 2))}/> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 2)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 2))}/>}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 3)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 3)} name="leavescheckboxes" disabled checked={getLeaveStatus(caliculateNextDay(weekStartDate, 3))}/> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 3)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 3))}/>}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 4)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 4)} name="leavescheckboxes" disabled checked={getLeaveStatus(caliculateNextDay(weekStartDate, 4))}/> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 4)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 4))}/>}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 5)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 5)} name="leavescheckboxes" disabled checked={getLeaveStatus(caliculateNextDay(weekStartDate, 5))}/> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 5)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 5))}/>}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 6)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 6)} name="leavescheckboxes" disabled checked={getLeaveStatus(caliculateNextDay(weekStartDate, 6))}/> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"leave_"+caliculateNextDay(weekStartDate, 6)} name="leavescheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 6))}/>}

                                                      </span>
                                                   </span>
                                                   <b>00:00</b>
                                                </div>
                                             )}
                                             {holidaysisChecked && (
                                                <div class="weekcallist" id="holidaypanel">
                                                   <span class="ps-4">Holiday</span>
                                                   <span class="checkbox-list align-items-center">
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(weekStartDate) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+weekStartDate} checked={getHolidayStatus(weekStartDate)} name="holidayscheckboxes" disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+weekStartDate} checked={getHolidayStatus(weekStartDate)} name="holidayscheckboxes" />}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 1)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 1)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 1))} disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 1)} checked={getHolidayStatus(caliculateNextDay(weekStartDate, 1))} name="holidayscheckboxes" />}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 2)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 2)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 2))} disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 2)} checked={getHolidayStatus(caliculateNextDay(weekStartDate, 2))} name="holidayscheckboxes" />}
                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 3)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 3)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 3))} disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 3)} checked={getHolidayStatus(caliculateNextDay(weekStartDate, 3))} name="holidayscheckboxes" />}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 4)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 4)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 4))} disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 4)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 4))}/>}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 5)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 5)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 5))} disabled /> :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 5)} checked={getHolidayStatus(caliculateNextDay(weekStartDate, 5))} name="holidayscheckboxes" />}

                                                      </span>
                                                      <span class="d-flex justify-content-center">

                                                         {checkDisablingStatus(caliculateNextDay(weekStartDate, 6)) == 1 ?
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 6)} name="holidayscheckboxes" checked={getHolidayStatus(caliculateNextDay(weekStartDate, 6))} disabled />
                                                         :
                                                            <input class="form-check-input" type="checkbox" value="" id={"holiday_"+caliculateNextDay(weekStartDate, 6)} checked={getHolidayStatus(caliculateNextDay(weekStartDate, 6))} name="holidayscheckboxes" />
                                                         }

                                                      </span>
                                                   </span>
                                                   <b>00:00</b>
                                                </div>
                                             )}
                                             {compoffsisChecked && (
                                                <div class="weekcallist" id="compoffpanel">
                                                   <span class="ps-4">Request Comp-off</span>
                                                   <span class="checkbox-list align-items-center">
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(weekStartDate) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+weekStartDate} name="compoffcheckboxes" checked={getCompoffStatus(weekStartDate)} disabled/>
                                                      :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+weekStartDate} name="compoffcheckboxes" checked={getCompoffStatus(weekStartDate)}/>
                                                      }
                                                      </span>
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(caliculateNextDay(weekStartDate, 1)) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 1)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 1))} disabled/>
                                                         :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 1)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 1))}/>
                                                      }
                                                      </span>
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(caliculateNextDay(weekStartDate, 2)) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 2)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 2))} disabled/>
                                                         :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 2)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 2))}/>
                                                      }
                                                      </span>
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(caliculateNextDay(weekStartDate, 3)) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 3)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 3))} disabled/>
                                                         :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 3)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 3))}/>
                                                      }
                                                      </span>
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(caliculateNextDay(weekStartDate, 4)) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 4)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 4))} disabled/>
                                                         :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 4)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 4))}/>
                                                      }
                                                      </span>
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(caliculateNextDay(weekStartDate, 5)) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 5)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 5))} disabled/>
                                                         :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 5)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 5))}/>
                                                      }
                                                      </span>
                                                      <span class="d-flex justify-content-center">
                                                      {checkCompoffDisablingStatus(caliculateNextDay(weekStartDate, 6)) == 1 ?
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 6)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 6))} disabled/>
                                                         :
                                                         <input class="form-check-input" type="checkbox" value="" id={"compoff_"+caliculateNextDay(weekStartDate, 6)} name="compoffcheckboxes" checked={getLeaveStatus(caliculateNextDay(weekStartDate, 6))}/>
                                                      }
                                                      </span>
                                                   </span>
                                                   <b>00:00</b>
                                                </div>
                                             )}
                                          </div> */}
                                       </div>
                                       : ''
                                    }
{showWeekTimeEtriesList.length === 0 ? <hr/> : ''}
                                    <div class="dayweektotal  ">
                                       <div class="weekcallist">
{/* {weekStartDate+' & '+weekEndDate+' & '+caliculateNextDay(weekStartDate, 6)} */}
                                             <span class="py-4 px-0">
                                                <a  onClick={() => handleAddTimeEntryWeek(weekStartDate, caliculateNextDay(weekStartDate, 6), totalStatusWeek)} class={`btn btn-outline-primary me-2 ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled' : ''}`} data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Add Row</a>
                                                <a  class={`btn btn-primary me-2 ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled' : ''}`} onClick={saveValues}>Save</a>
                                             </span>
                                             {showWeekTimeEtriesList.length > 0 ?

                                                <div>
                                                   <span class="weekcalmain">
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === weekStartDate ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 1) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 2) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 3) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 4) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 5) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                      <b class="d-flex justify-content-center">
                                                         {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 6) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                                      </b>
                                                   </span>

                                                </div>
                                                : ''}
                                                {showWeekTimeEtriesList.length > 0 ?
                                                 <div> <span><b class="mainbluetxt">{totalWeekTime.slice(0, -3)}</b></span></div>   : '' }
                                          </div>
                                       </div>
                                       {/* <div id="timeFormatvalidationMessage" class="alert alert-danger" role="alert">Task duration value should be less than 2360</div> */}
                                       <div id="weekupdatesuccessMessage" class="alert alert-success" role="alert">Time Entries Updated successfully!</div>

                                       {commentsData.length > 0 ?
                    <div class="commentwrap">
                        <b class="mb-2 d-block">Comments</b>
                        {commentsData.map((commentdata, commentindex) => (
                            <div class="commentItem my-2">
                                <div class="d-flex gap-2">
                                    <strong class="name">{commentdata.user.first_name+' '+commentdata.user.last_name}</strong>
                                    <span class="px-1">|</span>
                                    <span class="reason">{commentdata.comments}</span>
                                </div>
                                <span>Reviewed : <span>{handleCommentDatesDisplayFormat(commentdata.created_time)}</span></span>
                            </div>
                        ))}


                    </div>
                    : ''}

                                    {parseInt(totalWeekTime) > 0?
                                       <div class="btn-group my-4 ">
                                          <a href="# " onClick={() => submitTimesheetforApproval(weekStartDate, weekEndDate)} class={`btn btn-primary ${(totalStatusWeek === 'Submitted' || totalStatusWeek === 'Approved') ? 'disabled' : ''}`}>Submit for approval</a>
                                       </div>
                                       :
                                       <div></div>
                                    }
                              </div>

                              {showPopup && (
                                 <DeleteConfirmationPopup
                                    message="Are you sure you want to Delete this row?"
                                    onConfirm={handleDeleteRow}
                                    onCancel={handleCancel}
                                 />
                              )}

                              </form>
                           </div></div>
                        </div>


                        </div>
                     </div>

                  </div>

                  <AddTime onTrigger={handleAddTimeTrigger} edittimeentry={timeSheetEntryIdtoEdit} addrowday={selectedDateForTimeEntry} addrowweekday1={selectedStartDateForTimeEntry} addrowweekday2={selectedEndDateDateForTimeEntry} mytimeentryid={selectedTimeEntryIdForEdit} entryStatus={timesheetConsolidatedStatus} />


                  <TaskDescription project={selectedProject} client={selectedClient} task={selectedTask} isbillable={selectedBillable} result={selectedEntryResult} />

               {showLoader === 1 ?
                  <div class="loadwrapper">
                     <div class="clock"><div class="circle"></div></div>
                  </div>
                  : ''}
            </section>

         </main>
      </>
   )
};

TimesheetLanding.propTypes = {};

TimesheetLanding.defaultProps = {};

export default TimesheetLanding;
