import React,{useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import services from "../../../services/services";
import AddClient from "../../modals/add-client";
import AddProject from "../../modals/add-project";
import AddTask from "../../modals/add-task";
import $ from 'jquery';
import Pagination from "../../../utility/Pagination/Pagination";
import { useTable, useSortBy, useExpanded } from 'react-table';

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
      <div className="popup">
        <div className="popup-content">
          <p>{message}</p>
          <button onClick={onConfirm} class="btn btn-primary">Yes</button>
          <button onClick={onCancel} class="btn btn-outline-dark">No</button>
        </div>
      </div>
    );
  }

const ProjectManagement = () => {

    const projectTabRef = useRef(null);
    const clientTabRef = useRef(null);
    const taskTabRef = useRef(null);

    const location = useLocation();

    const useridval = useState(sessionStorage.getItem('userIdval'));
    const [loggedUser, setLoggedUser] = useState(sessionStorage.getItem('userRole'));
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));
    const [clientStatus, setClientStatus] = useState(1);
    const [clientStatusIsChecked, setClientStatusIsChecked] = useState(0);
    const [projectStatusIsChecked, setProjectStatusIsChecked] = useState(0);
    const [clientListInfo, setClientListInfo] = useState([]);
    const [selectedClientIdForEdit, setSelectedClientIdForEdit] = useState(null);
    const [selectedProjectIdForEdit, setSelectedProjectIdForEdit] = useState(null);
    const [selectedTaskIdForEdit, setSelectedTaskIdForEdit] = useState(null);
    const [selectedDynamicValue, setSelectedDynamicValue] = useState(null);
    

    const [projectListInfo, setProjectListInfo] = useState([]);
    const [ProjectStatus, setProjectStatus] = useState(1);
    const pagenum = 1;
    const pagesize = 3;
    const ITEMS_PER_PAGE = 300; // Per page show the data pagination
    //const Pagesize=5;
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [clientIdforUpdate, setClientIdforUpdate] = useState(null);
    const [clientNameforUpdate, setClientNameforUpdate] = useState(null);
    const [companyIdforUpdate, setCompanyIdforUpdate] = useState(null);
    const [clientStatustoUpdate, setClientStatustoUpdate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [projectIdforUpdate, setProjectIdforUpdate] = useState(null);
    const [projectStatustoUpdate, setProjectStatustoUpdate] = useState(null);

    const [taskIdforUpdate, setTaskIdforUpdate] = useState(null);
    const [taskStatustoUpdate, setTaskStatustoUpdate] = useState(null);
    const [taskStatus, setTaskStatus] = useState(1);

    const [taskListInfo, setTaskListInfo] = useState([]);
    const [taskHoursListInfo, setTaskHoursListInfo] = useState([]);
    const [taskFullDetails, setTaskFullDetails] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [activeInactiveIsChecked, setActiveInactiveIsChecked] = useState(false);

    const [showLoader, setShowLoader] = useState(0);

    function getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid) {
        setShowLoader(1);
        services.getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid).then((res) => {
            if(res.data.type === 'success'){
                setClientListInfo(res.data.data);
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / ITEMS_PER_PAGE));
                setCurrentPage(pagenumval);
            }
            setShowLoader(0);
        });
    }


    const handlePageChange2 = (offset,triggerevent) => {
        //alert(offset);
        var managerid = ''; var reqCompany = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
                reqCompany = companyid;
            }

            if(loggedUser.toLowerCase() === 'admin'){
                reqCompany = adminCompanyid;
            }
        
        getClientsList(reqCompany, clientStatus, offset, ITEMS_PER_PAGE, managerid);
    };

    const handleProjectPageChange2 = (offset,triggerevent) => {
        var projectstatus = $('#projectStatusToggle');
        var managerid = '';
        if(loggedUser.toLowerCase() === 'manager'){
            managerid = sessionStorage.getItem('userIdval');
        }
        if(projectstatus.checked){
            getProjects(clientForProject, 0, 'asc', offset, ITEMS_PER_PAGE, managerid);
        } else {
            getProjects(clientForProject, 1, 'asc', offset, ITEMS_PER_PAGE, managerid);
        }
    }

    const handleTaskPageChange2 = (offset,triggerevent) => {
        getAllTasksInfo(taskStatus, searchProj, offset, ITEMS_PER_PAGE);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');

        if (tab === 'client' && clientTabRef.current) {
            clientTabRef.current.click();
        } else if (tab === 'project' && projectTabRef.current) {
            clientTabRef.current.click();
            projectTabRef.current.click();

            // if(sessionStorage.getItem('userRole') === 'Admin'){
            //     getClientsList(companyid, 1, 1, ITEMS_PER_PAGE,'');
            // }
            // else {
            //     getClientsList(companyid, 1, 1, ITEMS_PER_PAGE, sessionStorage.getItem('userIdval'));
            // }	
            
            //loadTasks(1);
            /*
            if(sessionStorage.getItem('userRole') === 'Admin'){
               
                getProjects('', 1, 'asc', 1, ITEMS_PER_PAGE, '');
                getAllTasksInfo(1, searchProj, 1, ITEMS_PER_PAGE);
            }else{
                getProjects('', 1, 'asc', 1, ITEMS_PER_PAGE, sessionStorage.getItem('userIdval'));
                getAllTasksInfo(1, searchProj, 1, ITEMS_PER_PAGE);
            }   
                */
        } else if (tab === 'task' && taskTabRef.current) {
            
            taskTabRef.current.click();
        }else{
            clientTabRef.current.click();
            projectTabRef.current.click();
        }
    }, [location]);
    /*
    useEffect(() =>
    {
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);
        console.log(loggedinUserRole);
        if(loggedinUserRole.toLowerCase() === 'admin' || loggedinUserRole.toLowerCase() === 'manager'){
            var tab = getParameterByName('tab');
            setClientForProject('');
            var managerid = '';
            if(loggedinUserRole.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
            }
            console.log(managerid);
            
            //getClientsList(companyid, clientStatus, pagenum, ITEMS_PER_PAGE, managerid);
            
            //getProjects('', '1', 'asc', pagenum, pagesize);
           
            if(!isEmpty(tab)){
                if(tab === 'project'){
                    //document.getElementsByClassName("nav-link")[1].click();
                    projectTabRef.current.click();
                }

                if(tab === 'client'){
                    //document.getElementsByClassName("nav-link")[0].click();
                    clientTabRef.current.click();
                }

                if(tab === 'task'){
                   
                    //document.getElementsByClassName("nav-link")[2].click();
                    taskTabRef.current.click();
                    //loadTasks(1)
                    setTimeout(function() { loadTasks(1); }, 1000);
                }
            } else {
                document.getElementsByClassName("nav-link")[1].click();
                //projectTabRef.current?.click();
            }
        } else {
            window.location.href='/login';
        }
    },[pagenum]);
    */

    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const handleClientStatus = (event) => {
        if(event.target.checked){

            setClientStatusIsChecked(1);
            setClientStatus(0);
            var managerid = ''; var reqCompany = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
                reqCompany = companyid;
            }

            if(loggedUser.toLowerCase() === 'admin'){
                reqCompany = adminCompanyid;
            }
            getClientsList(reqCompany, 0, pagenum, ITEMS_PER_PAGE, managerid);
        } else {
            setClientStatus(1);
            setClientStatusIsChecked(0);
            var managerid = ''; var reqCompany = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
                reqCompany = companyid;
            }

            if(loggedUser.toLowerCase() === 'admin'){
                reqCompany = adminCompanyid;
            }
            getClientsList(reqCompany, 1, pagenum, ITEMS_PER_PAGE, managerid);
        }
    }

    const viewClientInfo = (clientid) => {

        setSelectedClientIdForEdit(clientid);
    }

    const changeClientStatus = (clientId, clientName, companyId, newStatus) => {
        setClientIdforUpdate(clientId);
        setClientNameforUpdate(clientName);
        setCompanyIdforUpdate(companyId);
        setClientStatustoUpdate(newStatus);
        setShowPopup(true);
    }

    const handleCancel = () => {
        setShowPopup(false);
    };

    const handleUpdate = () => {
        
        const requestObject = {
            "client_name": clientNameforUpdate,
            "address1": null,
            "address2": null,
            "city": null,
            "state": null,
            "zip": null,
            "company_id": companyIdforUpdate,
            "status": clientStatustoUpdate
        }
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        services.updateClientById(requestObject, clientIdforUpdate).then((res) => {
            //setIsOpen(false);
            $(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setShowPopup(false);
            setTimeout(function() { 
                var managerid = ''; var reqCompany = '';
                if(loggedUser.toLowerCase() === 'manager'){
                    managerid = sessionStorage.getItem('userIdval');
                    reqCompany = companyid;
                }
    
                if(loggedUser.toLowerCase() === 'admin'){
                    reqCompany = adminCompanyid;
                }
                if(clientStatustoUpdate === 1){
                    setClientStatusIsChecked(0);
                } else {
                    setClientStatusIsChecked(1);
                }
                
                getClientsList(reqCompany, clientStatustoUpdate, pagenum, ITEMS_PER_PAGE, managerid); 
            }, 1000);
        });
    }

    var projectsFromService ='';
    /*const getProjects = (cientlistid, projectstatusval, sortingorder,pagenumval, itemsperpage, managerid) => {
        services.getProjectsDetailsList(cientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid).then((res) => {
            if(res.data.type === 'success'){
                setProjectListInfo(res.data.data);
                projectsFromService = res.data.data;
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / itemsperpage));
                setCurrentPage(pagenumval);
            }
        });
    } */

    const getProjects = async (clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid) => {
        setShowLoader(1);
        try {
            var reqCompany = '';
            if(loggedUser.toLowerCase() === 'manager'){
                reqCompany = companyid;
            }

            if(loggedUser.toLowerCase() === 'admin'){
                reqCompany = adminCompanyid;
            }
            const res = await services.getProjectsDetailsList(clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid, 1, reqCompany);
            if (res.data.type === 'success') {
                setProjectListInfo(res.data.data);
                //setProjectsFromService(res.data.data); // Store the projects from the service if needed
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / itemsperpage));
                setCurrentPage(pagenumval);
            }
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
        finally{
            setShowLoader(0);
        }
    };

    const handleProjectStatus = (event) => {
        var clientfilter = $('#clientdropdown').val();
        if(event.target.checked){
            setProjectStatus(0);
            setProjectStatusIsChecked(1);
            var managerid = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
            }
            getProjects(clientfilter, 0, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
        } else {
            setProjectStatus(1);
            setProjectStatusIsChecked(0);
            var managerid = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
            }
            getProjects(clientfilter, 1, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
        }
    }

    const handleClientFilter = (event) => {
        var clientfilterselected = event.target.value;
        setClientForProject(clientfilterselected);
        var projectstatus = $('#projectStatusToggle');
        if(projectstatus.checked){
            var managerid = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
            }
            getProjects(clientfilterselected, 0, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
        } else {
            var managerid = '';
            if(loggedUser.toLowerCase() === 'manager'){
                managerid = sessionStorage.getItem('userIdval');
            }
            getProjects(clientfilterselected, 1, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
        }
    }

    const changeProjectStatus = (projectid, newStatus) => {
        setProjectIdforUpdate(projectid);
        setProjectStatustoUpdate(newStatus);
        setShowPopup(true);
    }

    function formatTimeToHHMM(value){
        // if (value === '') return '';

        // if (value.length <= 2) {
        //   return value; // Return as is if only two digits or less
        // }

        const time = String(value);
        const hours = time.substring(0, 2).padStart(2, '0');
        const minutes = time.substring(2, 4).padStart(2, '0');
        const seconds = time.substring(4, 6).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };


    const handleUpdateProject = () => {
        var requestObject = {};
        for(var p=0; p< projectListInfo.length; p++){
            if(projectListInfo[p].projectId === projectIdforUpdate){
                var requestObject = {
                    "name": projectListInfo[p].projectName,
                    "code": projectListInfo[p].projectCode,
                    "project_start_date": projectListInfo[p].projectStartDate,
                    "project_end_date": projectListInfo[p].projectEndDate,
                    "budget_time": formatTimeToHHMM(projectListInfo[p].allocatedHours),
                    "client_id": projectListInfo[p].clientId,
                    "project_status": projectStatustoUpdate
                }
            }
        }
        
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        setShowLoader(1);
        setShowPopup(false);
        services.updateProjectById(requestObject, projectIdforUpdate).then((res) => {
            //setIsOpen(false);
            //$(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setTimeout(function() { 
                var managerid = ''; var reqCompany = '';
                if(loggedUser.toLowerCase() === 'manager'){
                    managerid = sessionStorage.getItem('userIdval');
                    reqCompany = companyid;
                }
    
                if(loggedUser.toLowerCase() === 'admin'){
                    reqCompany = adminCompanyid;
                }
                if(projectStatustoUpdate === 1){
                    setProjectStatusIsChecked(0);
                } else {
                    setProjectStatusIsChecked(1);
                }
                getProjects(reqCompany, projectStatustoUpdate, 'asc', pagenum, ITEMS_PER_PAGE, managerid); 
            }, 1000);
            setShowLoader(0);
        });
    }

    const createNewProject = () => {
        setSelectedProjectIdForEdit(null);
    }

    const editProject = (projectidtoedit) => {
        setSelectedProjectIdForEdit(projectidtoedit);
    }

    const [searchTxt, setSearchTxt] = useState('');
    const [searchProj, setSearchProj] = useState('');
    const [searchTaskWithProjectId, setSearchTaskWithProjectId] = useState('');
    const [searchTaskWithBillable, setSearchTaskWithBillable] = useState('');
    const [searchTaskWithStatus, setSearchTaskWithStatus] = useState([]);

    const loadTasks = async (taskstatus) => {
        //alert("378")
        var reqCompany = '';
        if(loggedUser.toLowerCase() === 'manager'){
            reqCompany = companyid;
        }

        if(loggedUser.toLowerCase() === 'admin'){
            reqCompany = adminCompanyid;
        }

        if(sessionStorage.getItem('userRole') === 'Admin'){
            getClientsList(companyid, 1, 1, ITEMS_PER_PAGE,'');
        }
        else {
            getClientsList(companyid, 1, 1, ITEMS_PER_PAGE, sessionStorage.getItem('userIdval'));
        }	
        setClientForProject('');
        var managerid = '';
        if(loggedUser.toLowerCase() === 'manager'){
            managerid = sessionStorage.getItem('userIdval');
        }
        //alert(managerid);
        // getProjects('', 1, 'asc', 1, '', managerid);
        // getAllTasksInfo(taskstatus, searchProj, 1, ITEMS_PER_PAGE);

        await getProjects('', 1, 'asc', 1, '', managerid);
        // Now that projects are fetched and state is updated, call getAllTasksInfo
        getAllTasksInfo(taskstatus, searchProj, 1, ITEMS_PER_PAGE);
    }

    const getAllTasksInfo = (taskstatus, searchProj, pagenumval, itemsperpage) => {
        //alert("taskstatus:"+taskstatus+" searchProj:"+searchProj+" pagenumval:"+pagenumval+" itemsperpage:"+itemsperpage)
        var taskstatusses = [];
        if(taskstatus === 1){
            taskstatusses.push("New");
            taskstatusses.push("In Progress");
            taskstatusses.push("Re-opened");
        }
        else if(taskstatus === 0){
            taskstatusses.push("Completed");
            taskstatusses.push("Disabled");
            taskstatusses.push("Deleted");
        }
        else {
            taskstatusses.push(taskstatus);
        }

        
        var myProjectIdsFilter = [];
        if (isEmpty(searchProj)) {
            if (loggedUser.toLowerCase() === 'manager') {
                
                projectListInfo.forEach((project) => {
                    
                    if(project.projectManagerStatus == 'assigned'){
                        
                        
                        myProjectIdsFilter.push(project.projectId);
                    } 
                });
            }
            if (loggedUser.toLowerCase() === 'admin') {
                projectListInfo.forEach((project) => {
                        myProjectIdsFilter.push(project.projectId);
                });
            }
        } else {
            myProjectIdsFilter.push(searchProj);
        }
        console.log(myProjectIdsFilter);

        if(loggedUser.toLowerCase() === 'manager' && myProjectIdsFilter.length > 0){
            myTasksInfo(taskstatusses, searchTaskWithBillable, myProjectIdsFilter, searchTxt, pagenumval, itemsperpage);
        }

        if(loggedUser.toLowerCase() === 'admin'){
            myTasksInfo(taskstatusses, searchTaskWithBillable, myProjectIdsFilter, searchTxt, pagenumval, itemsperpage);
        }
        
    }

    const myTasksInfo = (taskstatussesVal, searchTaskWithBillableVal, myProjectIdsFilterVal, searchTxtVal, pagenumval, itemsperpageVal) => {
        setShowLoader(1);
        const requestObject =
        {
            "status": taskstatussesVal,
            "billable": searchTaskWithBillableVal,
            "projectId": myProjectIdsFilterVal,
            "search": searchTxtVal,
            "pageNumber": pagenumval,
            "pageSize": itemsperpageVal
        }
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getAllTasksInfo(requestObject).then((res) => {

            if(res.type === 'success'){
                var team_ids = [];
                var teamInfo = res.data;
                for (var i = 0; i < teamInfo.length; i++) {
                    team_ids.push(teamInfo[i].id);
                }
                setTaskListInfo(res.data);

                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / ITEMS_PER_PAGE));
                setCurrentPage(pagenumval);

                const requestObject = {
                    "userId": "",
                    "projectId": "",
                    "taskStatus": [],
                    "taskName": "",
                    "taskId": team_ids,
                    "pageNumber": pagenumval,
                    "pageSize": ITEMS_PER_PAGE
                  };
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'POST',
                //     headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };
                services.getTaskHours(requestObject).then((res) => {
                    if(res.type === 'success'){

                       setTaskHoursListInfo(res.data);
                       var taskhours = res.data

                       // Merge the data
                        var combinedData = [];
                        for (var i = 0; i < teamInfo.length; i++) {
                            for (var j = 0; j < taskhours.length; j++) {
                                if(teamInfo[i].id === taskhours[j].taskId){

                                    combinedData.push({
                                        "id": teamInfo[i].id,
                                        "name": teamInfo[i].name,
                                        "project_id": teamInfo[i].project_id,
                                        "sprint": teamInfo[i].sprint,
                                        "description": teamInfo[i].description,
                                        "duration": teamInfo[i].duration,
                                        "is_billable": teamInfo[i].is_billable,
                                        "task_status": teamInfo[i].task_status,
                                        "task_started_time": teamInfo[i].task_started_time,
                                        "task_ended_time": teamInfo[i].task_ended_time,
                                        "created_time": teamInfo[i].created_time,
                                        "updated_time": teamInfo[i].updated_time,
                                        "project": teamInfo[i].project,
                                        "estimated_hours": teamInfo[i].estimated_hours,
                                        "estimatedHours": taskhours[j].estimatedHours,
                                        "burnedHours": taskhours[j].burnedHours,
                                        "remainingHours": taskhours[j].remainingHours,
                                        "users": taskhours[j].users
                                    });
                                }
                            }
                        }
                        setMergedData(combinedData);
                    }
                });
            }
            setShowLoader(0);
        });
    }


    const handleTaskStatus = (event) => {
        var searchTaskwithTxt = $('#searchTaskwithTxt').val();
        if(event.target.checked){
            setTaskStatus(0);
            setActiveInactiveIsChecked(1);
            getAllTasksInfo(0, searchProj, 1, ITEMS_PER_PAGE);
        } else {
            setTaskStatus(1);
            setActiveInactiveIsChecked(0)
            getAllTasksInfo(1, searchProj, 1, ITEMS_PER_PAGE);
        }
    }

    const setSearchStatus = (event) => {
        var searchStatus = event.target.value;
        if(searchStatus === 'New' || searchStatus === 'In Progress' || searchStatus === 'Re-opened' || searchStatus == '1'){
            setTaskStatus(1);
            setActiveInactiveIsChecked(0);
        } else {
            setTaskStatus(0);
            setActiveInactiveIsChecked(1);
        }
        getAllTasksInfo(searchStatus, searchProj, 1, ITEMS_PER_PAGE);
    }

    const [formMode, setFormMode] = useState(null);
    const [projectidfortask, setprojectidfortask] = useState(null);
    const createTaskForProject = (projectid) => {
        setFormMode('add');
        setprojectidfortask(projectid);
        setSelectedTaskIdForEdit(null);
    }

    const editTaskWithId = (taskid) => {
        //alert(taskid);
        
        if(taskid == ''){
            setFormMode('add');
            setprojectidfortask(null);
            setSelectedTaskIdForEdit(taskid);
            setSelectedDynamicValue(Math.floor(Math.random() * (105 - 2 + 1)) + 10)
        }else{
            setFormMode('edit');
            setprojectidfortask(null);
            setSelectedTaskIdForEdit(taskid);
            setSelectedDynamicValue(Math.floor(Math.random() * (105 - 2 + 1)) + 10)
        }
        
    }
    /*
    useEffect(() => {
        editTaskWithId(selectedTaskIdForEdit)
      }, [selectedTaskIdForEdit]); // Re-run effect when selectedTaskIdForEdit changes

      */

    const changeTaskStatus = (taskid, newStatus) => {
        setTaskIdforUpdate(taskid);
        setTaskStatustoUpdate(newStatus);
        setShowPopup(true);
    }


    const handleUpdateTask = () => {
        setShowLoader(1);
        var requestObject = {};
        for(var p=0; p< taskListInfo.length; p++){
            if(taskListInfo[p].id === taskIdforUpdate){
                var requestObject = {
                   "name": taskListInfo[p].name,
                    "project_id":taskListInfo[p].project_id,
                    "sprint": taskListInfo[p].sprint,
                    "description": taskListInfo[p].description,
                    "duration": taskListInfo[p].duration,
                    "estimated_hours": taskListInfo[p].estimated_hours,
                    "is_billable": taskListInfo[p].is_billable,
                    "task_status": taskStatustoUpdate,
                    "task_started_time": taskListInfo[p].task_started_time,
                    "task_ended_time": taskListInfo[p].task_ended_time
                }
            }
        }
        setShowPopup(false);
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        services.updateTaskInfoById(requestObject, taskIdforUpdate).then((res) => {
            //setIsOpen(false);
            $(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setTimeout(function() { 
                if(taskStatustoUpdate === 1){
                    setActiveInactiveIsChecked(0);
                } else {
                    setActiveInactiveIsChecked(1);
                }
                loadTasks(taskStatustoUpdate); }, 1000);
            setShowLoader(0);
        });
    }

    const getFirstUserforTask = (taskid) => {
        for (var i = 0; i < mergedData.length; i++) {
            if(mergedData[i].id === taskid){
                var users = mergedData[i].users;
                if(users.length > 0){
                    return users[0].userFirstName+' '+users[0].userLastName;
                } else {
                    return '';
                }
            }
        }
    }

    const getProjectTaskDetails = (taskid, projectid) => {
        window.location.href = '/taskDetail?tab=task&tid='+taskid+'&pid='+projectid;
    }

    const handleSearchInputChange = (event) => {
        setSearchTxt(event.target.value);
        getAllTasksInfo(taskStatus, searchProj, 1, ITEMS_PER_PAGE);
    }

    const setProjectFilter = (event) => {
        setSearchProj(event.target.value);
        getAllTasksInfo(taskStatus, event.target.value, 1, ITEMS_PER_PAGE);
    }

   const [clientForProject, setClientForProject] = useState('');
    const showClientProjects = (clientid) => {
        setClientForProject(clientid);
        document.getElementsByClassName("projecttab")[0].click();
        var managerid = '';
        if(loggedUser.toLowerCase() === 'manager'){
            managerid = sessionStorage.getItem('userIdval');
        }
        getProjects(clientid, 1, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
    }

    const columns = React.useMemo(
        () => {
            const baseColumns = [
                {
                    Header: 'Client Name',
                    accessor: 'client_name',
                    width: '18%',
                    Cell: ({ value, row }) => (
                        <a href="#" onClick={() => showClientProjects(row.original.id)}>
                            {value}
                        </a>
                    ),
                    sortType: 'basic', // Enable sorting
                },
                {
                    Header: 'Project',
                    accessor: 'projects',
                    width: '30%',
                    Cell: ({ value }) => (
                        value.length > 0 ? (
                            
                            value.map((projectInfo, projectIndex) => (
                                
                                    <span key={projectIndex} className="badge rounded-pill project-badge text-dark my-2">
                                        {projectInfo.name}
                                    </span>
                                
                            ))
                            
                        ) : (
                            <span>No projects available</span>
                        )
                    ),
                    sortType: 'basic', // Enable sorting
                },
            ];
    
            if (loggedUser.toLowerCase() === 'admin') {
                baseColumns.push(
                    {
                        Header: 'Company',
                        accessor: row => row.company.company_name,
                        width: 'auto',
                        Cell: ({ value }) => <b>{value}</b>,
                        sortType: 'basic', // Enable sorting
                    },
                    {
                        Header: 'Actions',
                        accessor: 'actions',
                        width: '100px',
                        Cell: ({ row }) => (
                            <div className="dropdown table-action-dd">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt=""/>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => viewClientInfo(row.original.id)}> 
                                            <img src="images/edit.png" alt="" /> Edit Client
                                        </a>
                                    </li>
                                    {row.original.status === 1 ? (
                                        <li>
                                            <a className="dropdown-item" href="#" onClick={() => changeClientStatus(row.original.id, row.original.client_name, row.original.company_id, 0)}>
                                                <img src="images/delete.svg" alt=""/> Disable
                                            </a>
                                        </li>
                                    ) : (
                                        <li>
                                            <a className="dropdown-item" href="#" onClick={() => changeClientStatus(row.original.id, row.original.client_name, row.original.company_id, 1)}>
                                                <img src="images/greencheck.svg" alt=""/> Enable
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ),
                        disableSortBy: true, // Disable sorting for this column
                    }
                );
            }
    
            return baseColumns;
        },
        [loggedUser] // Add loggedUser as a dependency
    );
    
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: clientListInfo,
        },
        useSortBy
    );

    const projcolumns = React.useMemo(
        () => {
            const baseColumns = [
                {
                    Header: 'Project Name',
                    accessor: 'projectName',
                    width: 'auto',
                    Cell: ({ value, row }) => (
                        loggedUser.toLowerCase() === 'manager' ? (
                            (row.original.projectManagerStatus && row.original.projectManagerStatus[0] === 'assigned') ? (
                                <a href={`/projectDetail?id=${row.original.projectId}`}>{value}</a>
                            ) : (
                                <>{value} <div>
                                <small class="badge rounded-pill bg-success-orange mx-2 font-500 px-3 py-2">Unassigned</small>
                             </div></>  // Render the value inside React fragment when no link is required
                            )
                        ) : (
                            <a href={`/projectDetail?id=${row.original.projectId}`}>{value}</a>
                        )
                         
                    ),
                    sortType: 'basic',  // Enable sorting for this column
                                    },
                {
                    Header: 'Client Name',
                    accessor: 'clientName',
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div>{value}</div>
                    ),
                    sortType: 'basic',  // Enable sorting for this column
                },
                {
                    Header: 'Allocated Hrs',
                    accessor: 'allocatedHours',
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div>{parseInt(value)}</div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                    Header: 'Burned Hrs',
                    accessor: 'burnedHours',
                    width: '15%',
                    Cell: ({ value }) => (
                        <div>{value.toFixed(2)}</div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                    Header: 'Remaining Hrs',
                    accessor: 'remainingHours',
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div>
                            {parseInt(value) > 0 ? value.toFixed(2) : '...'}
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                    Header: 'Team Size',
                    accessor: 'teamSize',
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div>{value}</div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
            ];
    
            baseColumns.push(
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    width: '75px',
                    Cell: ({ row }) => (
                        loggedUser.toLowerCase() === 'manager' ? (
                            (row.original.projectManagerStatus && row.original.projectManagerStatus[0] === 'assigned') ? (
                                <div className="dropdown table-action-dd text-center">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt=""/>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => editProject(row.original.projectId)}> 
                                            <img src="images/edit.png" alt=""/> Edit Project
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => createTaskForProject(row.original.projectId)}>
                                            <img src="images/add.svg" alt=""/> Create Task(s)
                                        </a>
                                    </li>
                                    {(loggedUser.toLowerCase() === 'admin') && (
                                        <li>
                                            {row.original.projectStatus === 1 ? (
                                                <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 0)}>
                                                    <img src="images/delete.svg" alt=""/> Disable
                                                </a>
                                            ) : (
                                                <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 1)}>
                                                    <img src="images/greencheck.svg" alt=""/> Enable
                                                </a>
                                            )}
                                        </li>
                                    )}
                                </ul>
                            </div>
                            ) : (
                                <>N/A</>  // Render the value inside React fragment when no link is required
                            )
                        ) : (
                            <div className="dropdown table-action-dd text-center">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt=""/>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => editProject(row.original.projectId)}> 
                                            <img src="images/edit.png" alt=""/> Edit Project
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => createTaskForProject(row.original.projectId)}>
                                            <img src="images/add.svg" alt=""/> Create Task(s)
                                        </a>
                                    </li>
                                    {(loggedUser.toLowerCase() === 'admin') && (
                                        <li>
                                            {row.original.projectStatus === 1 ? (
                                                <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 0)}>
                                                    <img src="images/delete.svg" alt=""/> Disable
                                                </a>
                                            ) : (
                                                <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 1)}>
                                                    <img src="images/greencheck.svg" alt=""/> Enable
                                                </a>
                                            )}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )
                         
                        
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                    disableSortBy: true, 
                }
            );
    
            return baseColumns;
        },
        [loggedUser] // Add loggedUser as a dependency
    );

    const {
        getTableProps: getProjectTableProps,
        getTableBodyProps: getProjectTableBodyProps,
        headerGroups: projectHeaderGroups,
        rows: projectRows,
        prepareRow: prepareProjectRow,
    } = useTable(
        {
            columns: projcolumns,
            data: projectListInfo,
        },
        useSortBy
    );

    const taskcolumns = React.useMemo(
        () => {
            const baseColumns = [
                {
                    Header: 'Task Name',
                    accessor: 'name',
                    width: '238px',
                    Cell: ({ value, row }) => (
                        <div className="projecttask">
                            <span>
                                <a href="#" onClick={() => getProjectTaskDetails(row.original.id, row.original.project_id)}>{value}</a>
                            </span>
                            <span className="badge rounded-pill project-badge text-dark my-2">{row.original.project.name}</span>
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                    Header: 'Assigned to',
                    accessor: 'users', // Assuming users is an array of assigned users
                    width: '175px',
                    // Cell: ({ row }) => (
                    //     <div className="dualtask d-flex align-items-center">
                    //         <span className="name">{getFirstUserforTask(row.original.id)}</span>
                    //         <span className="count">
                    //             {parseInt((row.original.users.length) - 1) > 0 ? '+' + parseInt((row.original.users.length) - 1) : ' '}
                    //         </span>
                    //     </div>
                    // ),
                    Cell: ({ value, row }) => (
                        <div className="dualtask d-flex align-items-center"  onClick={() => toggleRow(row.index, row.original.users.length)}
                        style={{ cursor: 'pointer' }}>
                            <span className="name">{parseInt((row.original.users.length)) > 0 ? row.original.users[0].userFirstName+' '+row.original.users[0].userLastName : ''}</span>
                            <span className="count">
                                {parseInt((row.original.users.length) - 1) > 0 ? '+' + parseInt((row.original.users.length) - 1) : ' '}
                            </span>
                            
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                    Header: 'Sprint Details',
                    accessor: 'sprint',
                    width: '103px',
                    Cell: ({ value }) => <div className="text-center">{value}</div>,
                    sortType: 'basic', // Enable sorting for Sprint Details
                },
                {
                    Header: 'Estimated Hrs',
                    accessor: 'estimated_hours',
                    width: '103px',
                    Cell: ({ value }) => <div className="text-center">{value}</div>,
                    sortType: 'basic', // Enable sorting for Estimated Hrs
                },
                {
                    Header: 'Burned Hrs',
                    accessor: 'burnedHours',
                    width: '85px',
                    Cell: ({ row }) => (
                        <div className="text-left position-relative" style={{ paddingLeft: "30px" }}>
                            {parseInt(row.original.estimatedHours) > 0 && parseInt(row.original.burnedHours) > parseInt(row.original.estimatedHours) ? (
                                <>
                                    {parseInt(row.original.estimatedHours)}
                                    <span className="extrahr">
                                        +{parseInt(row.original.burnedHours) - parseInt(row.original.estimatedHours)}
                                    </span>
                                </>
                            ) : (
                                parseInt(row.original.burnedHours)
                            )}
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Burned Hrs
                },
                {
                    Header: 'Remaining Hrs',
                    accessor: 'remainingHours',
                    width: '103px',
                    Cell: ({ value }) => (
                        <div className="text-center">
                            {parseInt(value) > 0 ? parseInt(value) : '...'}
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Remaining Hrs
                },
                {
                    Header: 'Status',
                    accessor: 'task_status',
                    width: '75px',
                    Cell: ({ value }) => (
                        <div className="d-grid text-center">
                            <img
                                src={`images/${value.toLowerCase().replace(/\s+/g, '-')}.svg`}
                                className="m-auto"
                                alt=""
                            />
                            <small>{value}</small>
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Status
                },
                {
                    Header: 'Action',
                    accessor: 'actions',
                    width: '75px',
                    Cell: ({ row }) => (
                        (row.original.task_status === 'New' || row.original.task_status === 'In progress' || row.original.task_status === 'Re-opened') ? (
                                <div className="dropdown table-action-dd text-center">
                            <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="images/dots.svg" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                        <img src="images/edit.png" alt="" /> Edit
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Completed')}>
                                        <img src="images/greentick.svg" alt="" /> Complete
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Disabled')}>
                                        <img src="images/delete.svg" alt="" /> Disable
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Deleted')}>
                                        <img src="images/delete.svg" alt="" /> Delete
                                    </a>
                                </li>
                            </ul>
                        </div>
                            ) : ( (row.original.task_status === 'Disabled') ? (
                                <div className="dropdown table-action-dd text-center">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                            <img src="images/edit.png" alt="" /> Edit
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Completed')}>
                                            <img src="images/greentick.svg" alt="" /> Complete
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'New')}>
                                            <img src="images/greencheck.svg" alt="" /> Enable
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            ) : ( (row.original.task_status === 'Completed') ? (
                                <div className="dropdown table-action-dd text-center">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                            <img src="images/edit.png" alt="" /> Edit
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Re-opened')}>
                                            <img src="images/reopen.svg" alt="" /> Re-Open
                                        </a>
                                    </li>
                                </ul>
                            </div> )
                            : ('...')
                        )
                            
                              
                            ) 
                        
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                    disableSortBy: true, 
                },

                {
                    // Additional row for showing user details if multiple users exist
                    Header: () => null,
                    id: 'userDetails',
                    Cell: ({ row }) => (
                        row.original.users.length > 1 ? (
                            <tr className="hiddenRow">
                                <td colSpan="8">
                                    <div className="accordian-body collapse" id={`demo${row.index}`}>
                                        <table className="table coltableinn" style={{ backgroundColor: "#e9e9e9" }}>
                                            <tbody>
                                                {row.original.users.map((user, idx) => (
                                                    <tr key={idx}>
                                                        <td style={{ width: "238px" }}></td>
                                                        <td style={{ width: "175px" }}>{user.userFirstName} {user.userLastName}</td>
                                                        <td className="text-center" style={{ width: "103px" }}></td>
                                                        <td className="text-center" style={{ width: "103px" }}>...</td>
                                                        <td className="text-left" style={{ width: "85px", paddingLeft: "20px" }}>{parseInt(user.userBurnedHours)}</td>
                                                        <td className="text-center" style={{ width: "103px" }}>...</td>
                                                        <td style={{ width: "75px" }}>{capitalizeFirstLetter(user.taskUserStatus)}</td>
                                                        <td style={{ width: "75px" }}></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        ) : null
                    ),
                }
            ];
    
            return baseColumns;
        },
        [loggedUser] // Dependency array
    );
    
    // Use the columns and data in the table
    const {
        getTableProps: getTaskTableProps,
        getTableBodyProps: getTaskTableBodyProps,
        headerGroups: taskHeaderGroups,
        rows: taskRows,
        prepareRow: prepareTaskRow,
    } = useTable(
        {
            columns: taskcolumns,
            data: mergedData, // Replace with your actual data source
        },
        useSortBy // Enable sorting
    );
    

    const capitalizeFirstLetter = (status) => {
        if (!status) return '';
        return status.charAt(0).toUpperCase() + status.slice(1);
      };


      const [expandedRows, setExpandedRows] = useState({});

      const handleToggle = (rowId) => {
          setExpandedRows((prev) => ({
              ...prev,
              [rowId]: !prev[rowId],
          }));
      };

      const toggleRow = (rowIndex, usersLength) => {
        if(usersLength > 1){
            setExpandedRows((prevState) => ({
                ...prevState,
                [rowIndex]: !prevState[rowIndex],
            }));
        }
    };
  
      const taskColumns2 = React.useMemo(
          () => [
              {
                  Header: 'Task Name',
                  accessor: 'name',
                  width: '238px',
                  Cell: ({ value, row }) => (
                      <div className="projecttask">
                          <span>
                              <a href="#" onClick={() => getProjectTaskDetails(row.original.id, row.original.project_id)}>
                                  {value}
                              </a>
                          </span>
                          <span className="badge rounded-pill project-badge text-dark my-2">
                              {row.original.project.name}
                          </span>
                      </div>
                  ),
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Assigned to',
                  accessor: 'users',
                  width: '175px',
                  Cell: ({ row }) => (
                      <>
                          <div className="dualtask d-flex align-items-center" onClick={() => toggleRow(row.index, row.original.users.length)}
                        style={{ cursor: 'pointer' }}>
                              <span className="name">
                                  {parseInt(row.original.users.length) > 0
                                      ? `${row.original.users[0].userFirstName} ${row.original.users[0].userLastName}`
                                      : ''}
                              </span>
                              <span className="count">
                                  {parseInt(row.original.users.length - 1) > 0 ? '+' + parseInt(row.original.users.length - 1) : ''}
                              </span>
                          </div>
                          
  
                          {/* Toggle button for accordion */}
                        {/*  {row.original.users.length > 1 && (
                              <button
                                  className="btn btn-link"
                                  onClick={() => handleToggle(row.id)}
                                  aria-expanded={expandedRows[row.id] || false}
                              >
                                  Toggle Users
                              </button>
                          )} */}
                      </>
                  ),
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Sprint Details',
                  accessor: 'sprint',
                  width: '103px',
                  Cell: ({ value }) => <div className="text-center">{value}</div>,
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Estimated Hrs',
                  accessor: 'estimated_hours',
                  width: '103px',
                  Cell: ({ value }) => <div className="text-center">{parseInt(value) > 0 ? parseInt(value) : '...'}</div>,
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Burned Hrs',
                  accessor: 'burnedHours',
                  width: '85px',
                  Cell: ({ row }) => (
                      <div className="text-left position-relative" style={{ paddingLeft: '30px' }}>
                          {parseInt(row.original.estimatedHours) > 0 && parseInt(row.original.burnedHours) > parseInt(row.original.estimatedHours) ? (
                              <>
                                  {parseInt(row.original.estimatedHours)}
                                  <span className="extrahr">
                                      +{parseInt(row.original.burnedHours) - parseInt(row.original.estimatedHours)}
                                  </span>
                              </>
                          ) : (
                              parseInt(row.original.burnedHours)
                          )}
                      </div>
                  ),
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Remaining Hrs',
                  accessor: 'remainingHours',
                  width: '103px',
                  Cell: ({ value }) => <div className="text-center">{parseInt(value) > 0 ? parseInt(value) : '...'}</div>,
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Status',
                  accessor: 'task_status',
                  width: '75px',
                  Cell: ({ value }) => (
                      <div className="d-grid text-center">
                          <img
                              src={`images/${value.toLowerCase().replace(/\s+/g, '-')}.svg`}
                              className="m-auto"
                              alt=""
                          />
                          <small>{value}</small>
                      </div>
                  ),
                  sortType: 'basic', // Enable sorting for Task Name
              },
              {
                  Header: 'Action',
                  accessor: 'actions',
                  width: '75px',
                  Cell: ({ row }) => (
                      <div className="dropdown table-action-dd text-center">
                          <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="images/dots.svg" alt="" />
                          </button>
                          <ul className="dropdown-menu">
                              <li>
                                  <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                      <img src="images/edit.png" alt="" /> Edit
                                  </a>
                              </li>
                              <li>
                                  <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Completed')}>
                                      <img src="images/greentick.svg" alt="" /> Complete
                                  </a>
                              </li>
                              <li>
                                  <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Disabled')}>
                                      <img src="images/delete.svg" alt="" /> Disable
                                  </a>
                              </li>
                          </ul>
                      </div>
                  ),
                  sortType: 'basic', // Enable sorting for Task Name
                    disableSortBy: true, 
              },
          ],
          []
      );
  
      const { 
        getTableProps: getTaskTableProps2,
        getTableBodyProps: getTaskTableBodyProps2,
        headerGroups: taskHeaderGroups2,
        rows: taskRows2,
        prepareRow: prepareTaskRow2,
       } = useTable(
          { columns: taskColumns2, data : mergedData },
          useSortBy
      );

  return(
  <>
             <main>


<section>
<div class="container">
        <h2 class="mainHeadline">
            Project Management
        </h2>

        <div class="tab-primary">

            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    {sessionStorage.getItem('userRole') === 'Admin' ?
                        <a ref={clientTabRef} class="nav-link active bg-none clienttab" onClick={() => getClientsList(adminCompanyid, 1, 1, ITEMS_PER_PAGE,'')} data-bs-toggle="tab" href="#one">Clients</a>
                    :
                        <a ref={clientTabRef} class="nav-link active bg-none clienttab" onClick={() => getClientsList(companyid, 1, 1, ITEMS_PER_PAGE, sessionStorage.getItem('userIdval'))} data-bs-toggle="tab" href="#one">Clients</a>
                    }
                </li>
                <li class="nav-item">
                    {sessionStorage.getItem('userRole') === 'Admin' ?
                        <a ref={projectTabRef} class="nav-link bg-none projecttab" onClick={() => getProjects('', 1, 'asc', 1, ITEMS_PER_PAGE, '')} data-bs-toggle="tab" href="#two">Projects</a>
                    :
                        <a ref={projectTabRef} class="nav-link bg-none projecttab" onClick={() => getProjects('', 1, 'asc', 1, ITEMS_PER_PAGE, sessionStorage.getItem('userIdval'))} data-bs-toggle="tab" href="#two">Projects</a>
                    }
                </li>
                <li class="nav-item">
                    <a  ref={taskTabRef} class="nav-link bg-none tasktab" onClick={() => loadTasks(1)} data-bs-toggle="tab" href="#three">Tasks </a>
                </li>

            </ul>

            <div class="tab-content ">

                <div id="one" class="container tab-pane fade px-0 active show" role="tabpanel">

                    <div class="d-flex justify-content-between align-items-center pt-2 pb-2">
                        <div class="d-flex justify-content-end align-items-center ms-auto" style={{"width": "auto"}}>
                            <span>Status:</span>
                            <div class="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleClientStatus} checked={clientStatusIsChecked}/>
                                <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                            </div>
                        </div>
                    </div>

                    <div class="tbl-container bdr mt-0">
                    <table {...getTableProps()} className="table mb-0">
                        <thead className="bg-lightblue">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={{
                                                width: column.width,
                                                position: 'relative',

                                                cursor: 'pointer',
                                            }}
                                        >
                                            {column.render('Header')}
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    display: 'inline-block',
                                                    fontSize: '0.8em',
                                                    color: '#888',
                                                }}
                                            >
                                                {
                                                    !column.disableSortBy ? (
                                                        column.isSorted ? (
                                                            column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                        ) : '↕️'
                                                    ) : null
                                                }
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                                rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="6">
                                        <div className="d-grid text-center py-3">
                                            <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                            <span> No Users Available</span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                                </table>


                    {/*
                        <table class="table mb-0">
                            <thead class="bg-lightblue">
                                <tr>
                                    <th scope="col" style={{"width": "auto"}}>Client Name</th>
                                    <th scope="col" style={{"width": "auto"}}>Project</th>
                                    {(loggedUser.toLowerCase() === 'admin') && (
                                    <>
                                    <th scope="col" style={{"width": "auto"}}>Company</th>
                                    <th scope="col" style={{"width": "100px"}}>Actions</th>
                                    </>
                                    )}

                                </tr>
                            </thead>

                            {clientListInfo.length > 0 ?
                                <tbody>
                                    {clientListInfo.map((data, index) => (
                                        <tr>
                                            <td style={{"width": "auto"}}><a href="#" onClick={() => showClientProjects(data.id)}>{data.client_name}</a></td>
                                            {clientListInfo.length > 0 ?
                                            <td style={{"width": "auto"}}>
                                                {data.projects.map((projectInfo, projectIndex) => (
                                                    <div><span class="badge rounded-pill project-badge text-dark my-2">{projectInfo.name}</span></div>
                                                ))}
                                            </td> :
                                            <td style={{"width": "auto"}}>

                                            </td>
                                            }

                                            {(loggedUser.toLowerCase() === 'admin') && (
                                            <>
                                            <td style={{"width": "auto"}}><b>{data.company.company_name}</b></td>
                                            <td>
                                                <div class="dropdown table-action-dd">

                                                    <button class="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/dots.svg" alt=""/>
                                                    </button>

                                                    <ul class="dropdown-menu">

                                                        <li>
                                                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => viewClientInfo(data.id)}> <img src="images/edit.png" alt="" /> Edit Client</a>
                                                        </li>
                                                        {data.status === 1 ?
                                                        <li>
                                                            <a class="dropdown-item" href="#" onClick={() => changeClientStatus(data.id, data.client_name, data.company_id, 0)}><img src="images/delete.svg" alt=""/> Disable</a>
                                                        </li>
                                                        :
                                                        <li>
                                                            <a class="dropdown-item" href="#" onClick={() => changeClientStatus(data.id, data.client_name, data.company_id, 1)}><img src="images/greencheck.svg" alt=""/> Enable</a>
                                                        </li>
                                                        }

                                                    </ul>
                                                </div>
                                            </td>
                                            </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colspan="4">
                                    <div className="d-grid text-center py-3">
                                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                        <span> No Clients Available</span>
                                        </div>
                                       </td>
                                </tr>
                            </tbody> }


                        </table>  */}

                    </div>

                    {showPopup && (
                        <ConfirmationPopup
                        message="Are you sure you want to Update this Client?"
                        onConfirm={handleUpdate}
                        onCancel={handleCancel}
                        />
                    )}

                       {/* <div class="paginationContent mt-3">
                            <Pagination
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={handlePageChange2}/>

                        </div> */}

                </div>
                <div id="two" class="container tab-pane px-0 fade" role="tabpanel">
                    <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
                    {(loggedUser.toLowerCase() === 'admin')?
                        <div class="col-lg-3">
                            <button class="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => createNewProject()}><span class="mx-2">+</span> Create Project</button>

                        </div>
                    : ''}
                        <div class="d-flex justify-content-end gap-4 align-items-center w-100">
                            <div class="d-flex justify-content-end align-items-center  ">
                                <span style={{"min-width": "69px"}}>Clients:</span>
                                <select class="form-select" id="clientdropdown" onChange={handleClientFilter} value={clientForProject} style={{"max-width": "200px"}}>
                                    <option value="" selected>All</option>
                                    {clientListInfo.map((data, index) => (
                                        <option value={data.id} >{data.client_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="d-flex justify-content-end align-items-center ">
                                <span>Status:</span>
                                <div class="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                    <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                    <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="projectStatusToggle" onChange={handleProjectStatus} checked={projectStatusIsChecked}/>
                                    <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tbl-container bdr mt-0">

                    <table {...getProjectTableProps()} className="table mb-0">
                                    <thead className="bg-lightblue">
                                        {projectHeaderGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        style={{
                                                            width: column.width,
                                                            position: 'relative',
            
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {
                                                    !column.disableSortBy ? (
                                                        column.isSorted ? (
                                                            column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                        ) : '↕️'
                                                    ) : null
                                                }
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getProjectTableBodyProps()}>
                                        {projectRows.length > 0 ? (
                                            projectRows.map(row => {
                                                prepareProjectRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                        <span> No Projects Available</span>
                        </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

{/*
                        <table class="table mb-0">
                            <thead class="bg-lightblue">
                                <tr>

                                    <th scope="col" style={{"width": "auto"}}>Project Name</th>
                                    <th scope="col" style={{"width": "auto"}}>Client Name</th>
                                    <th scope="col" style={{"width": "auto"}}>Allocated Hrs</th>
                                    <th scope="col" style={{"width": "auto"}}>Burned Hrs</th>
                                    <th scope="col" style={{"width": "auto"}}>Remaining Hrs</th>
                                    <th scope="col" style={{"width": "auto"}}>Team Size</th>
                                    <th scope="col" style={{"width": "75px"}} class="text-center">Actions</th>
                                </tr>
                            </thead>
                            {projectListInfo.length > 0 ?
                            <tbody>
                                {projectListInfo.map((projectdata, projectindex) => (
                                <tr>
                                    <td><a href={`/projectDetail?id=${projectdata.projectId}`}>{projectdata.projectName}</a></td>

                                    <td class="text-center; width:auto">{projectdata.clientName}</td>

                                    <td class="text-center; width:auto">{parseInt(projectdata.allocatedHours)}</td>
                                    <td class="text-center; width:auto">{(projectdata.burnedHours).toFixed(2)}</td>
                                    <td class="text-center; width:auto">{parseInt(projectdata.remainingHours) > 0 ? (projectdata.remainingHours).toFixed(2) : '...'}</td>
                                    <td class="text-center; width:auto">{projectdata.teamSize}</td>



                                    <td style={{"width": "auto", "text-align": "center"}}>
                                        <div class="dropdown table-action-dd">

                                            <button class="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="images/dots.svg" alt=""/>
                                            </button>

                                            <ul class="dropdown-menu">

                                                <li>
                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => editProject(projectdata.projectId)}> <img src="images/edit.png" alt=""/> Edit Project</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => createTaskForProject(projectdata.projectId)}><img src="images/add.svg" alt=""/> Create Task(s)</a>
                                                </li>
                                                {(loggedUser.toLowerCase() === 'admin') && (
                                                <li>
                                                {projectdata.projectStatus === 1 ?
                                                <a class="dropdown-item" href="#" onClick={() => changeProjectStatus(projectdata.projectId, 0)}><img src="images/delete.svg" alt="" /> Disable</a>
                                                :
                                                <a class="dropdown-item" href="#" onClick={() => changeProjectStatus(projectdata.projectId, 1)}><img src="images/greencheck.svg" alt=""/> Enable</a>
                                                }
                                                </li>
                                                )}
                                            </ul>
                                        </div>

                                    </td>
                                </tr>
                                ))}

                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colspan="4">

                                    <div className="d-grid text-center py-3">
                                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                        <span>No Projects Available</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                        </table> */}
                    </div>
                    {showPopup && (
                        <ConfirmationPopup
                        message="Are you sure you want to Update this Project?"
                        onConfirm={handleUpdateProject}
                        onCancel={handleCancel}
                        />
                    )}

                    {/*<div class="paginationContent mt-3">
                        <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={handleProjectPageChange2}/>

                    </div> */}
                </div>

                <div id="three" class="container tab-pane px-0 fade" role="tabpanel">

                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-3 pt-1 px-0">
                                    <button onClick={() => editTaskWithId('')} class="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#exampleModalct"><span class="mx-2">+</span> Create
                                        Task</button>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text" class="form-control searchKeyInput" id="searchTaskwithTxt" onChange={handleSearchInputChange} placeholder="Search by task" aria-describedby="emailHelp" style={{"background-color": "#ebebeb"}} />
                                </div>
                                <div class="col-lg-2">
                                    <div class="d-flex justify-content-end align-items-center ms-auto">
                                        <span style={{"min-width": "69px"}}>Projects:</span>
                                        <select class="form-select" id="projectdropdown" style={{"max-width": "200px"}} onChange={setProjectFilter}>
                                            <option value="" selected>All</option>
                                            {projectListInfo.map((projectdropdowndata, projectdropdownindex)=>(
                                                <option value={projectdropdowndata.projectId}>{projectdropdowndata.projectName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="d-flex justify-content-end align-items-center ms-auto">
                                        <span style={{"min-width": "69px"}}>Status:</span>
                                        <select class="form-select" id="inputGroupSelect01" style={{"max-width": "300px"}} onChange={setSearchStatus}>
                                            <option value="1">Select</option>
                                            <option value="New">New</option>
                                            <option value="In Progress" >In Progress</option>
                                            <option value="Completed" >Completed</option>
                                            <option value="Re-opened" >Re-Opened</option>
                                            <option value="Disabled" >Disabled</option>
                                            <option value="Deleted" >Deleted</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="d-flex justify-content-end align-items-center ms-auto">
                                        <span></span>
                                        <div class="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                            <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                            <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleTaskStatus}  checked={activeInactiveIsChecked}/>
                                            <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="panel panel-default">

                        <div class="tbl-container bdr mt-0">
                       {/* <table {...getTaskTableProps()} className="table mb-0">
                                    <thead className="bg-lightblue">
                                        {taskHeaderGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        style={{
                                                            width: column.width,
                                                            position: 'relative',
            
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {
                                                    !column.disableSortBy ? (
                                                        column.isSorted ? (
                                                            column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                        ) : '↕️'
                                                    ) : null
                                                }
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTaskTableBodyProps()}>
                                        {taskRows.length > 0 ? (
                                            taskRows.map(row => {
                                                prepareTaskRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                        <span> No Tasks Available</span>
                        </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>  */}

                                <table className="table table-condensed" {...getTaskTableProps2()}>
            <thead className="bg-lightblue">
                {taskHeaderGroups2.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}  style={{
                                width: column.width,
                                position: 'relative',

                                cursor: 'pointer',
                            }}>
                                {column.render('Header')}
                                <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {
                                                    !column.disableSortBy ? (
                                                        column.isSorted ? (
                                                            column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                        ) : '↕️'
                                                    ) : null
                                                }
                                                        </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTaskTableBodyProps2()}>
                {taskRows2.map((row) => {
                    prepareTaskRow2(row);
                    return (
                        <React.Fragment key={row.id}>
                            <tr {...row.getRowProps()} className="accordion-toggle">
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>

                            {/* Sub-row for additional users */}
                            {expandedRows[row.id] && (
                                <tr>
                                    <td colSpan={8}>
                                        <div className="collapse show">
                                            <table className="table coltableinn" style={{ backgroundColor: '#e9e9e9' }}>
                                                <tbody>
                                                    {row.original.users.slice(1).map((user, userIndex) => (
                                                        <tr key={userIndex}>
                                                            <td style={{ width: '238px' }}></td>
                                                            <td className="text-start" style={{ width: '175px' }}>
                                                                {user.userFirstName + ' ' + user.userLastName}
                                                            </td>
                                                            <td className="text-center" style={{ width: '103px' }}></td>
                                                            <td className="text-center" style={{ width: '103px' }}>...</td>
                                                            <td className="text-left" style={{ width: '85px', paddingLeft: '20px' }}>
                                                                {parseInt(user.userBurnedHours)}
                                                            </td>
                                                            <td className="text-center" style={{ width: '103px' }}>...</td>
                                                            <td style={{ width: '75px' }}>{capitalizeFirstLetter(user.taskUserStatus)}</td>
                                                            <td style={{ width: '75px' }}></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>

                          {/*  <table class="table table-condensed">
                                <thead class="bg-lightblue">
                                    <tr>
                                        <th style={{"width":"238px"}}>Task Name</th>
                                        <th style={{"width":"175px"}}>Assigned to</th>
                                        <th class="text-center" style={{"width":"103px"}}>Sprint Details</th>
                                        <th class="text-center" style={{"width":"103px"}}>Estimated Hrs</th>
                                        <th class="text-left" style={{"width":"85px"}}>Burned Hrs</th>
                                        <th class="text-center" style={{"width":"103px"}}>Remaining Hrs</th>
                                        <th class="text-center" style={{"width":"75px"}}>Status</th>
                                        <th style={{"width":"75px"}}>Action</th>
                                    </tr>
                                </thead>
{mergedData.length > 0 ?
                                <tbody>
                                    {mergedData.map((taskdata, taskindex) => (
                                        <>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#demo`+taskindex} class="accordion-toggle">

                                        <td style={{"width": "238px"}}>
                                            <div class="projecttask">
                                                <span><a href="#" onClick={() => getProjectTaskDetails(taskdata.id, taskdata.project_id)}>{taskdata.name}</a></span>
                                                <span class="badge rounded-pill project-badge text-dark my-2">{taskdata.project.name}</span>

                                            </div>
                                        </td>
                                        <td style={{"width":"175px"}}>
                                            <div class="dualtask d-flex align-items-center"><span class="name">{getFirstUserforTask(taskdata.id)}</span> <span class="count">{parseInt((taskdata.users.length) - 1) > 0 ? '+'+parseInt((taskdata.users.length) - 1) : ' '}</span></div>
                                        </td>
                                        <td class="text-center" style={{"width":"103px"}}>{taskdata.sprint}</td>
                                        {/* <td class="text-center" style={{"width":"103px"}}>{parseInt(taskdata.estimatedHours) > 0 ? parseInt(taskdata.estimatedHours) : '...'}</td> **
                                        <td class="text-center" style={{"width":"103px"}}>{taskdata.estimated_hours}</td>
                                        <td class="text-left position-relative" style={{"width":"85px", paddingLeft:"30px"}}>
                                            {(parseInt(taskdata.estimatedHours) > 0 && parseInt(taskdata.burnedHours) > parseInt(taskdata.estimatedHours)) ?
                                                <>{parseInt(taskdata.estimatedHours)}
                                                <span className="extrahr">+{parseInt(taskdata.burnedHours) - parseInt(taskdata.estimatedHours)}</span></>
                                            : parseInt(taskdata.burnedHours) }</td>
                                        <td class="text-center" style={{"width":"103px"}}>{parseInt(taskdata.remainingHours) > 0 ? parseInt(taskdata.remainingHours) : '...'}</td>
                                        <td class="text-center" style={{"width":"75px"}}>
                                            <div class="d-grid">
                                                {taskdata.task_status === 'Completed' ?
                                                <img src="images/completed.svg" class="m-auto" alt=""/>
                                                :
                                                taskdata.task_status === 'Deleted' ?
                                                <img src="images/delete-red.svg" class="m-auto" alt=""/>
                                                :
                                                taskdata.task_status === 'Re-opened' ?
                                                <img src="images/reopen.svg" class="m-auto" alt=""/>
                                                :
                                                taskdata.task_status === 'Disabled' ?
                                                <img src="images/delete.svg" class="m-auto" alt=""/>
                                                :
                                                taskdata.task_status === 'New' ?
                                                <img src="images/new.svg" class="m-auto" alt=""/>
                                                :
                                                <img src="images/in-progress.svg" class="m-auto" alt=""/>
                                                }
                                                <small>{taskdata.task_status}</small>

                                            </div>
                                        </td>
                                        <td style={{"width": "75px", "text-align": "center"}}>
                                            <div class="dropdown table-action-dd">

                                                <button class="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="images/dots.svg" alt=""/>
                                                </button>
        {(taskdata.task_status !== 'New' || taskdata.task_status !== 'In Progress' || taskdata.task_status !== 'Re-opened') ?
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(taskdata.id)}> <img src="images/edit.png" alt=""/> Edit</a></li>

                <li><a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Completed')}><img src="images/greentick.svg" alt=""/> Complete</a></li>

                <li><a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Disabled')}><img src="images/delete.svg" alt=""/> Disable </a></li>

                <li><a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Deleted')}><img src="images/delete.svg" alt="" /> Delete</a></li>
            </ul>
        :
        taskdata.task_status !== 'Disabled' ?
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(taskdata.id)}> <img src="images/edit.png" alt=""/> Edit</a></li>

                <li><a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'New')}><img src="images/greencheck.svg" alt=""/> Enable </a></li>

                <li><a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Completed')}><img src="images/greentick.svg" alt=""/> Complete</a></li>
            </ul>
        :
        taskdata.task_status !== 'Completed' ?
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(taskdata.id)}> <img src="images/edit.png" alt=""/> Edit</a></li>

                <li><a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Re-opened')}><img src="images/reopen.svg" alt=""/> Re-Open</a></li>
            </ul>
        : '...'

        }

                                            </div>

                                        </td>
                                    </tr>

                                    {parseInt(taskdata.users.length) > 1 ?
                                    <tr>
                                    <td colspan="8" class="hiddenRow">
                                        <div class="accordian-body collapse" id={`demo`+taskindex}>
                                            <table class="table coltableinn" style={{"background-color": "#e9e9e9"}}>
                                                <thead>

                                                </thead>

                                                <tbody>
                                                {taskdata.users.map((taskuserdata, taskuserindex) => (
                                                    <tr data-bs-toggle="collapse" class="accordion-toggle" data-bs-target="#demo10">
                                                        <td style={{"width":"238px"}}></td>
                                                        <td class="text-start" style={{"width":"175px"}}>{taskuserdata.userFirstName+' '+taskuserdata.userLastName}
                                                        </td>
                                                        <td class="text-center" style={{"width":"103px"}}></td>
                                                        <td class="text-center" style={{"width":"103px"}}>...</td>
                                                        <td class="text-left" style={{"width":"85px", paddingLeft:"20px"}}>{parseInt(taskuserdata.userBurnedHours)}</td>
                                                        <td class="text-center" style={{"width":"103px"}}>...</td>
                                                        <td style={{"width":"75px"}}>{capitalizeFirstLetter(taskuserdata.taskUserStatus)}</td>
                                                        <td style={{"width":"75px"}}></td>
                                                    </tr>
                                                ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                     : ' '}

                                    </>
                                    ))}

                                </tbody>
                                :
                                <tbody>
                                    <tr><td colSpan={8}>
                                    <div className="d-grid text-center py-3">
                                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                        <span> No Tasks Available</span>
                                        </div>
                                        </td></tr>

                                    </tbody>
}
                            </table>  */}
                        </div>
                        {showPopup && (
                        <ConfirmationPopup
                        message="Are you sure you want to Update this Task?"
                        onConfirm={handleUpdateTask}
                        onCancel={handleCancel}
                        />
                    )}

{/*
<div class="paginationContent mt-3">
                        <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={handleTaskPageChange2}/>

                    </div> */}
                    </div>
                    
                </div>
            </div>
            {showLoader === 1 ?
                        <div class="loadwrapper">
                            <div class="clock"><div class="circle"></div></div>
                        </div>
                        : '' }
        </div>

    </div>

    <AddProject editProject={selectedProjectIdForEdit}/>

    <AddTask formMode={formMode} taskProject={projectidfortask} editTask={selectedTaskIdForEdit} dynamic={selectedDynamicValue} />

    <AddClient editClient={selectedClientIdForEdit} isOpenModal='1'/>



    {/* <div class="modal fade" id="editday" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Time Entry</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <span class="d-flex align-items-center mb-3">
                        <img class="icon" src="images/cal-icon.png" width="28" alt=""/>
                        <div class="d-grid mx-2 ">
                            <span>Wednesday, 14 Feb</span>
                </div>



                </span>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Client Name:</label>
                    <select class="form-select" aria-label="Default select example">
                            <option selected>Select Client</option>
                            <option value="1">Small Nation</option>
                            <option value="2">Option Scanner</option>
                            <option value="3">Yodel</option>
                        </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Project Name:</label>
                    <select class="form-select" aria-label="Default select example">
                            <option selected>Select Project</option>
                            <option value="1">Small Nation</option>
                            <option value="2">Option Scanner</option>
                            <option value="3">Yodel</option>
                        </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Task Name:</label>
                    <select class="form-select" aria-label="Default select example">
                            <option selected>Select Task</option>
                            <option value="1">Small Nation</option>
                            <option value="2">Option Scanner</option>
                            <option value="3">Yodel</option>
                        </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label"> Task Description:</label>
                    <div class="form-floating mb-3">
                        <textarea class="form-control pt-1" placeholder="Leave a comment here" id="floatingTextarea2Disabled" disabled>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</textarea>

                    </div>
                </div>

                <div class="form-group mb-2">

                    <div class="form-floating">
                        <textarea class="form-control pt-1" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                        <label for="floatingTextarea2">Give details about the duties performed.</label>
                    </div>
                </div>
                <div class="py-2">
                    <div class="d-grid justify-content-end">
                        <div class="d-flex align-items-center">
                            <span>Enter your task duration :</span>
                            <input class="addtimeinput" type="text" placeholder="00:00"/>
                        </div>
                        <div class="d-flex align-items-center gap-2 py-2 form-switch">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                            <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                            <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <div>
                        <button type="button" class="btn btn-primary">Update</button>
                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
                    </div>
                    <a href="#" class="btn btn-outline-danger">Cancel</a>
                </div>

            </div>
        </div>
    </div>
    </div>

    <div class="modal fade" id="editday" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Time Entry</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <span class="d-flex align-items-center mb-3">
                        <img class="icon" src="images/cal-icon.png" width="28" alt=""/>
                        <div class="d-grid mx-2 ">
                            <span>Wednesday, 14 Feb</span>
                </div>



                </span>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Client Name:</label>
                    <select class="form-select" aria-label="Default select example">
                            <option selected>Select Client</option>
                            <option value="1">Small Nation</option>
                            <option value="2">Option Scanner</option>
                            <option value="3">Yodel</option>
                        </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Project Name:</label>
                    <select class="form-select" aria-label="Default select example">
                            <option selected>Select Project</option>
                            <option value="1">Small Nation</option>
                            <option value="2">Option Scanner</option>
                            <option value="3">Yodel</option>
                        </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Task Name:</label>
                    <select class="form-select" aria-label="Default select example">
                            <option selected>Select Task</option>
                            <option value="1">Small Nation</option>
                            <option value="2">Option Scanner</option>
                            <option value="3">Yodel</option>
                        </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label"> Task Description:</label>
                    <div class="form-floating mb-3">
                        <textarea class="form-control pt-1" placeholder="Leave a comment here" id="floatingTextarea2Disabled" disabled>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</textarea>

                    </div>
                </div>

                <div class="form-group mb-2">

                    <div class="form-floating">
                        <textarea class="form-control pt-1" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                        <label for="floatingTextarea2">Give details about the duties performed.</label>
                    </div>
                </div>
                <div class="py-2">
                    <div class="d-grid justify-content-end">
                        <div class="d-flex align-items-center">
                            <span>Enter your task duration :</span>
                            <input class="addtimeinput" type="text" placeholder="00:00"/>
                        </div>
                        <div class="d-flex align-items-center gap-2 py-2 form-switch">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                            <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                            <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <div>
                        <button type="button" class="btn btn-primary">Update</button>
                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
                    </div>
                    <a href="#" class="btn btn-outline-danger">Cancel</a>
                </div>

            </div>
        </div>
    </div>
    </div> */}

    {/* <div class="modal fade" id="exampleModal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project Name*</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Task Name"/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project Code <span>(Optional)</span> </label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Task Name"/>
                    </div>

                    <div class="form-group mb-2">
                        <div class="d-flex justify-content-between">
                            <div>
                                <label for="recipient-name" class="col-form-label">Start &amp; End date (Optional)
                                </label>
                                <div class="d-flex justify-content-start gap-1">
                                    <input style={{"max-width": "100px"}} type="email" class="form-control" id="exampleInputEmail1" placeholder=" Time"/>
                                    <input style={{"max-width": "100px"}} type="email" class="form-control" id="exampleInputEmail1" placeholder=" Time"/>
                                </div>
                            </div>
                            <div class="form-group mb-2">

                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Time"/>
                            </div>
                        </div>
                    </div>


                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Assign Manager </label>
                        <input disabled="" type="email" class="form-control" id="exampleInputEmail1" placeholder="Shankar Parepally"/>
                    </div>


                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Assign Users</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected="">All</option>
                            <option value="1">Kishore Keerthi</option>
                            <option value="2">Prasad Chilakapati </option>
                            <option value="3">Sandeep Deshpande</option>
                        </select>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Client</label>
                        <input disabled="" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Small Nation"/>
                    </div>


                    <div class="py-2">
                        <div class="d-grid justify-content-end">
                            <div class="d-flex align-items-center">
                                <span>Enter your task duration :</span>
                                <input class="addtimeinput" type="text" placeholder="00:00"/>
                            </div>
                            <div class="d-flex align-items-center gap-2 py-2 form-switch">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary">Update</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div> */}
{/*
    <div class="modal fade" id="exampleModaledit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Task</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Taks Name*</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Task Name"/>
                    </div>
                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project Name </label>
                        <select class="form-select" aria-label="Default select example">
                        <option selected="">Select Project</option>
                        <option value="1">HTO</option>
                        <option value="2">Masilab</option>
                        <option value="3">Option Scanner</option>
                    </select>
                    </div>
                    <div class="form-group mb-2 d-grid">
                        <label for="recipient-name" class="col-form-label">Assign to Name </label>
                        <select class="selectpicker" multiple aria-label="Default select example" data-live-search="true">
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            <option value="4">Four</option>
                          </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Sprint (optional)</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Task Name"/>
                    </div>
                    <div class="py-2">
                        <div class="d-grid justify-content-end">
                            <div class="d-flex align-items-center">
                                <span>Enter your task duration :</span>
                                <input class="addtimeinput" type="text" placeholder="00:00"/>
                            </div>
                            <div class="d-flex align-items-center gap-2 py-2 form-switch">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary">Update Task</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div> */}
</section>

</main>

  </>
)};

ProjectManagement.propTypes = {};

ProjectManagement.defaultProps = {};

export default ProjectManagement;
