import React,{useEffect} from "react";
import services from "../../services/services";


const Home = () => {


    useEffect(() => {
      sessionStorage.setItem("userRole", "Employee");

    }, []);

  return(
  <>
   <main>


         <section>
            <div class="container">
               Timesheet Home
            </div>
         </section>
         
      </main>
  </>
)};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
