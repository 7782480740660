import React,{useEffect, useState} from "react";
import services from "../../../services/services";
import DateObject from "react-date-object";
import TimesheetHeader from "./timesheet-header";

const AdminApproved = () => {
   //const useridval = 4;
   const [pendingForApprovalList, setPendingForApprovalList] = useState([]);
   const [useridval, setuseridval] = useState(0);

   useEffect(() =>
   {
    console.log('1');
        var timeSheetStatus = 'Submitted';
        getTimesheetEntriesPendingForApproval(timeSheetStatus);

        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
   },[1]);

   function getTimesheetEntriesPendingForApproval(timeSheetStatus) {
        const requestObject =
        {
           // "userIds":[useridval],
            "userIds":[useridval],
            "timeSheetStatuses":[timeSheetStatus]
        }
        console.log(requestObject);
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTimeEntriesByStatus(requestObject).then((res) => {
            console.log(res);
            setPendingForApprovalList(res.data);
        });
    }

    const getDateRange1 = (weekstart, weekend) => {
        var weekStartobj = new DateObject(weekstart);
        var weekEndobj = new DateObject(weekend);
        return weekStartobj.format("DD MMM YYYY")+' - '+weekEndobj.format("DD MMM YYYY");
    }

    const getTotalTimePendingForApproval = () => {
        for(var i=0; i< pendingForApprovalList.length; i++){
            var userinfodata =  pendingForApprovalList[i].users;
            //console.log(userinfodata);
            for(var j=0; j< userinfodata.length; j++){
                if(userinfodata[j].userId == useridval){
                    return userinfodata[j].workedHours;
                }
            }
        }
    }

    const showSubmittedWeekTimesheet = (mondaydate, sundaydate) => {
        sessionStorage.setItem('viewlistfor', mondaydate+'&'+sundaydate);
    }

  return(
  <>
             <main>


<section>
   <div class="container">
      <h2 class="mainHeadline">Timesheet</h2>
      <div class="tab-primary">
         <TimesheetHeader />

         <div class="tab-content ">

                <div id="three" class="container tab-pane fade px-0 active show" role="tabpanel">


                    <div class="d-flex justify-content-between align-items-center p-2">
                        <div class="d-flex justify-content-end align-items-center ms-auto" style={{"width": "320px"}}>
                            <span style={{"min-width": "69px"}}>Sort by:</span>
                            <select class="form-select" id="inputGroupSelect01" style={{"max-width": "200px"}}>
                                <option selected="Select">Week</option>
                                <option value="1">Week</option>
                                <option value="2">User</option>

                              </select>
                        </div>
                    </div>
                    <div class="pendingweeklistwrap">
                        <div class="weekheader">
                            <span>12 Feb 2024 - 18 Feb 2024</span>
                        </div>
                        <div class="weekitemlistitem d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start gap-2">

                                <div class="d-flex candinfo">
                                    <span class="shortname">KK</span>
                                    <span>Kishore Keerthi</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end align-items-center">
                                <div class="hours scale65">
                                    <span class="hrtime">02:00 <div class="hrlabel">Hrs</div></span>

                                </div>
                                <button class="btn btn-outline-primary scale85 ">View Details</button>
                            </div>
                        </div>
                        <div class="weekitemlistitem d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start gap-2">

                                <div class="d-flex candinfo">
                                    <span class="shortname">CP</span>
                                    <span>Chilakapati Prasad</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end align-items-center">
                                <div class="hours scale65">
                                    <span class="hrtime">02:00 <div class="hrlabel">Hrs</div></span>

                                </div>
                                <button class="btn btn-outline-primary scale85 ">View Details</button>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
         </div>

      </div>
</section>

</main>

  </>
)};

AdminApproved.propTypes = {};

AdminApproved.defaultProps = {};

export default AdminApproved;
